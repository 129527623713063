<template>
    
    <div class="flex items-center justify-between md:justify-end w-full">
        
        <div class="border border-solid border-white rounded-xl p-1.5 mt-5 md:mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer bg-gray-500 hover:bg-gray-600"
            @click="router_push_to( {
                name:'cart',
                params: { activetableroute : ''}
            })
        ">
            <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                <div>
                    <!-- CART  -->
                    <svg class="h-12 w-12 md:h-16 md:w-16 fill-white hover:fill-[#41c2dc]" viewBox="0 0 20 20" fill="currentColor" stroke-width="1">
                        <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                    </svg>
                    <!-- END CART END  -->
                    <span class="absolute bottom-28 right-[-8px] flex justify-center items-center align-top w-10 h-10 text-2xl font-bold text-white rounded-full" v-bind:style='{ backgroundColor : this.settings.theme_color_2.count}'> {{cart_count}} </span>
                </div>
                <div class="text-xl font-semibold text-white text-center">
                    Sepetim<br />&nbsp;
                </div>
            </div>


        </div>

        <div class="border border-solid border-white rounded-xl p-1.5 mt-5 md:mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative hidden items-center cursor-pointer" :style="{ backgroundColor: this.BackgroundColor1 }" @mouseover="this.BackgroundColor1 = this.settings.theme_color_2.banner_button_color_hover" @mouseout="this.BackgroundColor1 = this.settings.theme_color_2.banner_button_color" 
            @click="router_push_to({
                name:'dash',
                params: {
                    activetableroute: 'notifications'
                }
            })"
            >  
            <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                <div>
                    <!-- NOTIFICAION BELL  -->
                    <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                    <!-- END NOTIFICAION BELL END  -->
                    <span class="absolute bottom-24 right-[-8px] flex justify-center items-center align-top w-10 h-10 text-2xl font-bold text-white rounded-full" v-bind:style='{ backgroundColor : this.settings.theme_color_2.count }'> {{notif_count}} </span>
                </div>
                <div class="text-xl font-semibold text-center" v-bind:style='{ color : this.settings.theme_color_2.banner_button_text_color }'>
                    Bildirimler
                </div>
            </div>
        </div>


        <div class="border border-solid border-white rounded-xl p-1.5 mt-5 md:mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer" :style="{ backgroundColor: this.BackgroundColor1 }" @mouseover="this.BackgroundColor1 = this.settings.theme_color_2.banner_button_color_hover" @mouseout="this.BackgroundColor1 = this.settings.theme_color_2.banner_button_color" 
            @click="router_push_to({
                name:'returncart',
                params: {
                    activetableroute: 'returnlist'
                }
            })"> 

            <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                <div>
                    <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-green-300 text-white hover:text-black" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z" />
                    </svg>

                    <span class="absolute bottom-24 right-[-8px] flex justify-center items-center align-top w-10 h-10 text-2xl font-bold text-white rounded-full" v-bind:style='{ backgroundColor : this.settings.theme_color_2.count }'> {{ return_cart_count }} </span>
                </div>
                <div class="text-xl font-semibold text-center" v-bind:style='{ color : this.settings.theme_color_2.banner_button_text_color }'>
                    İade<br />İşlemleri
                </div>
            </div>
        </div>

        <!-- CASH -->
        <div class="border border-solid border-white rounded-xl p-1.5 mt-5 md:mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer" :style="{ backgroundColor: this.BackgroundColor2 }" @mouseover="this.BackgroundColor2 = this.settings.theme_color_2.banner_button_color_hover" @mouseout="this.BackgroundColor2 = this.settings.theme_color_2.banner_button_color" 
            @click="router_push_to({
                name:'payment',
                params: {
                    activetableroute: 'orders'
                }
            })"
            > 

            <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                <div>
                    <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    <!-- END CASH END -->
                </div>
                <div class="text-xl font-semibold text-center" v-bind:style='{ color : this.settings.theme_color_2.banner_button_text_color }'>
                    Ödeme<br />Yap
                </div>
            </div>
        </div>

        <!-- CHARTS ICON  -->
        <div class="border border-solid border-white rounded-xl p-1.5 mt-5 md:mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer" :style="{ backgroundColor: this.BackgroundColor3 }" @mouseover="this.BackgroundColor3 = this.settings.theme_color_2.banner_button_color_hover" @mouseout="this.BackgroundColor3 = this.settings.theme_color_2.banner_button_color" 
        @click="router_push_to({
                name:'dash',
                params: {
                    activetableroute: 'commercialtransactions'
                }
            })">
            <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                <div>
                    <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-teal-600 hover:stoke-red-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /> 
                    </svg>
                </div>
                <div class="text-xl font-semibold text-center" v-bind:style='{ color : this.settings.theme_color_2.banner_button_text_color }'>
                    Cari<br />Hareketler
                </div>
            </div>

        </div>

        <!-- HEART ICON  -->

        <div class="border border-solid border-white rounded-xl p-1.5 mt-5 md:mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer" :style="{ backgroundColor: this.BackgroundColor4 }" @mouseover="this.BackgroundColor4 = this.settings.theme_color_2.banner_button_color_hover" @mouseout="this.BackgroundColor4 = this.settings.theme_color_2.banner_button_color" 
            @click="router_push_to({
                name: 'dash',
                params: {
                        activetableroute: 'favorites'
                    },
                }  
            )">
            <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                <div>
                    <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    <span class="absolute bottom-24 right-[-8px] flex justify-center items-center align-top w-10 h-10 text-2xl font-bold text-white rounded-full" v-bind:style='{ backgroundColor : this.settings.theme_color_2.count }'> {{faves_count}} </span>
                </div>
                <div class="text-xl font-semibold text-center" v-bind:style='{ color : this.settings.theme_color_2.banner_button_text_color }'>
                    Favoriler<br />&nbsp;
                </div>
            </div>
        </div>


        <div class="border border-solid border-white rounded-xl p-1.5 mt-5 md:mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer" :style="{ backgroundColor: this.BackgroundColor5 }" @mouseover="this.BackgroundColor5 = this.settings.theme_color_2.banner_button_color_hover" @mouseout="this.BackgroundColor5 = this.settings.theme_color_2.banner_button_color" 
            @click="router_push_to({
                name: 'dash',
                params: {
                        activetableroute: 'orders'
                    },
                }
            )">
            <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                <div>
                    <!-- ORDER ICON  -->
                    <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                    </svg>
                </div>
                <div class="text-xl font-semibold text-center" v-bind:style='{ color : this.settings.theme_color_2.banner_button_text_color }'>
                    Siparişler<br />&nbsp;
                </div>
            </div>
        </div>

        <div class="border border-solid border-white rounded-xl p-1.5 mt-5 md:mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block text-white items-center cursor-pointer" :style="{ backgroundColor: this.BackgroundColor6 }" @mouseover="this.BackgroundColor6 = this.settings.theme_color_2.banner_button_color_hover" @mouseout="this.BackgroundColor6 = this.settings.theme_color_2.banner_button_color" 
            @click="router_push_to({
                name: 'dash',
                    params: {
                        activetableroute: 'islem_listesi'
                    },
                }
            )" >
            <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                <div>
                    <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                    </svg>
                </div>
                <div class="text-xl font-semibold text-center" v-bind:style='{ color : this.settings.theme_color_2.banner_button_text_color }'>
                    İşlem<br />Listesi
                </div>
            </div>
        </div>

    </div>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            BackgroundColor1: "#FFFFFF",
            BackgroundColor2: "#FFFFFF",
            BackgroundColor3: "#FFFFFF",
            BackgroundColor4: "#FFFFFF",
            BackgroundColor5: "#FFFFFF",
            BackgroundColor6: "#FFFFFF",
        }
    },

    created() {
        this.BackgroundColor1 = this.settings.theme_color_2.banner_button_color,
        this.BackgroundColor2 = this.settings.theme_color_2.banner_button_color,
        this.BackgroundColor3 = this.settings.theme_color_2.banner_button_color,
        this.BackgroundColor4 = this.settings.theme_color_2.banner_button_color,
        this.BackgroundColor5 = this.settings.theme_color_2.banner_button_color,
        this.BackgroundColor6 = this.settings.theme_color_2.banner_button_color;
    },

    methods: {
        ...mapActions(
            ['get_fast_cari','req_detailed_faves']
            
        ),

        router_push_to( locationNparams ){
            this.$router.push({
                name:locationNparams.name,
                params: locationNparams.params
            })

            // console.log('localtion params to chejc undefined')
            // console.dir(
            //     locationNparams
            // )
            if(locationNparams.name == 'login'){
                // console.log('login reseting state')
                this.$store.dispatch('reset_state_1',{});
            }
            if(locationNparams.name == 'dash' && locationNparams.params.activetableroute == 'commercialtransactions')this.get_fast_cari();
            if(locationNparams.name == 'payment')this.get_fast_cari();

            // B 17.11.2022 guncel tags bilgileri aliniyor
            if ((locationNparams.name == 'dash') || (locationNparams.name == 'cart' ) || (locationNparams.params.activetableroute == 'favorites')){
                this.get_account_info()
            }
            // E 17.11.2022 guncel tags bilgileri aliniyor
            // B 30.11.2022  -   Favorilerim sayfa duzenlemesi
            if (locationNparams.params.activetableroute == 'favorites'){
                    this.req_detailed_faves()
            }
            // E 30.11.2022  -   Favorilerim sayfa duzenlemesi
        },

        async get_account_info(){


            if (this.account_info.lastlogindate != undefined){ // 10.03.2023 - Gunluk giris tarih kontrolu
              const controlToday    = new Date();
              const controlDate     = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
              const UserDate        = new Date(this.account_info.lastlogindate);
              const controlUserDate = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
              if (Number(controlDate) != Number(controlUserDate)){
                this.$router.push(
                    {name: 'login'}
                )
              } else {// 09.03.2023 - Sistem guncellenme kontrolu 
                    const system_status_info =  await this.$store.dispatch('system_control')
                    .then( (res) => {
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )

                    if (!system_status_info){
                        this.$router.push(
                            {name: 'login'}
                        )
                        // console.log(system_status_info)
                        // console.log("this.account_info.system_status:" + this.account_info.system_status)

                    } else { // 17.11.2022 guncel tags bilgileri aliniyor
                        const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                        .then( (res) => {
                            //console.log("Kullanıcı bulundu !")
                            return res
                        })
                        .catch(
                            (err)=>{
                                console.log(err)
                            }
                        )
                    }
              }
            } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }
            }
        },
    },

    computed: {
        ...mapGetters({
            account_info        : 'get_account',
            cart_info           : 'get_account_cart_items',
            return_cart_info    : 'get_account_return_cart_items',
            settings            : 'get_settings',
        }),

        // changeBackgroundColor() {
        //     this.BackgroundColor = this.settings.theme_color_2.banner_button_color_hover;
        //     console.log("BackgroundColor:" + this.BackgroundColor)
        // },

        // resetBackgroundColor() {
        //     this.BackgroundColor = this.settings.theme_color_2.banner_button_color;
        //     console.log("BackgroundColor:" + this.BackgroundColor)
        // },

        cart_count () {
            const retthis = this.cart_info.filter(
                i => {return i.itemID}
            ).length
            return retthis
        },
        return_cart_count () {
            if (this.return_cart_info == undefined){
                return 0
            } else {
                var retthis = 0;
                for (let i=0 ; i < this.return_cart_info.length; i++){
                    if (this.return_cart_info[i].qty != undefined){
                        retthis = retthis + this.return_cart_info[i].qty;
                    }
                }
                // const retthis = this.return_cart_info.reduce((a,b) => { return a.qty + b.qty, 0})
                return retthis
            }
        },
        faves_count(){
            return (this.account_info.favorite_products == undefined) ? 0 : this.account_info.favorite_products.length //03.12.2022 - favorite_products degeri yok ise islem yapiliyor
        },
        notif_count() {
            return (this.account_info.stock_notify == undefined) ? 0 : this.account_info.stock_notify.length //03.12.2022 - stock_notify degeri yok ise islem yapiliyor
        }


    }
}








/*
<!-- MENU LIST -->

<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
</svg>

<!-- CHARTS -->

<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

<!-- heart -->
<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
</svg>

<!-- CART  -->

<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
</svg>


<!-- USER -->

<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
</svg>


<!-- NOTIFICAION BELL  -->
<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
</svg>
<!-- END NOTIFICAION BELL END  -->


----------- DELETE  THRASH ---------------
<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg>



------------- NEW PLUS ----------------------

<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
</svg>


---------------------- chevrON ASAGI -----------------------------

<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
</svg>



-----------------  GEAR IMACJE 

<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
</svg>


<div class="header__right">
    <div class="header__actions"><a class="header__extra" href="#">
                    
                    <!-- <i class="icon-chart-bars"></i> -->
                    <svg class="h-10 w-10 hover:fill-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /> 
                    </svg>
                    
                    <span><i>0</i></span></a><a class="header__extra" href="#">
                        
                        <!-- <i class="icon-heart"></i> -->
                        <svg class="h-10 w-10 hover:fill-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>

                        
                        <span><i>0</i></span></a>
                    
                    
                    <div @click="this.$router.push('/cart')" class="ps-cart--mini"><a class="header__extra" href="#">
                        
                        
                        <!-- <i class="icon-bag2"></i> -->

                        <svg class="h-10 w-10 hover:fill-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                        </svg>

                        
                        
                        <span><i>0</i></span></a>
                        <div class="ps-cart__content">
                            <div class="ps-cart__items">
                                <div class="ps-product--cart-mobile">
                                    <div class="ps-product__thumbnail"><a href="#"><img src="img/products/clothing/7.jpg" alt=""></a></div>
                                    <div class="ps-product__content"><a class="ps-product__remove" href="#"><i class="icon-cross"></i></a><a href="product-default.html">MVMTH Classical Leather Watch In Black</a>
                                        <p><strong>Sold by:</strong> YOUNG SHOP</p><small>1 x $59.99</small>
                                    </div>
                                </div>
                                <div class="ps-product--cart-mobile">
                                    <div class="ps-product__thumbnail"><a href="#"><img src="img/products/clothing/5.jpg" alt=""></a></div>
                                    <div class="ps-product__content"><a class="ps-product__remove" href="#"><i class="icon-cross"></i></a><a href="product-default.html">Sleeve Linen Blend Caro Pane Shirt</a>
                                        <p><strong>Sold by:</strong> YOUNG SHOP</p><small>1 x $59.99</small>
                                    </div>
                                </div>
                            </div>
                            <div class="ps-cart__footer">
                                <h3>Sub Total:<strong>$59.99</strong></h3>
                                <figure><a class="ps-btn" href="shopping-cart.html">View Cart</a><a class="ps-btn" href="checkout.html">Checkout</a></figure>
                            </div>
                        </div>
                    </div>





                    <div class="ps-block--user-header cursor-pointer" @click="this.$router.push('/dash')">
                        <div class="ps-block__left"> 
                            <!--<i class="icon-user"> </i>--> 
                            <svg class="h-10 w-10 hover:fill-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>

                        </div>
                               <!-- <div class="ps-block__right"><a href="my-account.html">Login</a><a href="my-account.html">Register</a></div> -->
                    </div>



    </div>
</div>






*/

</script>