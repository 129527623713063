<template>
    <header v-if="(settings.theme_design == 1)" class="hidden md:block fixed top-0 left-0 right-0 z-[1000] shadow-md shadow-gray-400" data-sticky="true">
        <div class="flex pb-8 pt-8 border-b-1 border-white border-solid" v-bind:style='{ backgroundColor : settings.theme_color.banner }'>
            <div class="flex flex-row grid-cols-3 max-w-[1650px] mt-0 mb-0 mr-auto ml-auto w-full pl-9 pr-9 justify-between items-center">
                <div class="relative left-2 basis-2/12">
                    <a @click="to_home_push" class="cursor-pointer"><img src="/images/logo.png" class="pb-5 max-h-[100%]"/></a>
                </div>
                <div class="relative w-full basis-6/12 justify-center">
                    <search_v/>
                </div>
                <div class="relative basis-3/12 justify-center float-right w-full">
                    <Topiconsmenu class = ""/>
                </div>
                <div class="relative basis-1/12 justify-center w-full pt-0">
                    <div class="flex flex-col text-xl border border-solid rounded-xl p-3 justify-center" v-bind:style='{ backgroundColor : settings.theme_color.currency, borderColor: this.settings.theme_color.banner_text_color }'>
                        <div v-for="rate in exchange_rate" :key="rate" v-bind:style='{ color : this.settings.theme_color.banner_text_color }'>
                            {{ rate.symbol}} : {{ rate.forexsat }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <header v-else-if="(settings.theme_design == 2)" class="hidden md:block fixed top-0 left-0 right-0 z-[1000] shadow-md shadow-gray-400" data-sticky="true">
        <div class="flex pb-2 pt-2 border-b-1 border-white border-solid" v-bind:style='{ backgroundColor : settings.theme_color_2.banner }'>
            <div class="flex flex-row grid-cols-3 max-w-[1650px] mt-0 mb-0 mr-auto ml-auto w-full pl-9 pr-9 justify-between items-center">
                <div class="relative block left-2 basis-2/12">
                    <a @click="to_home_push" class="cursor-pointer"><img src="/images/logo-2.png" class="pb-5 max-h-[100%]"/></a>
                </div>
                <div class="relative w-full basis-5/12 justify-center">
                    <search_v2/>
                </div>
                <div class="flex flex-col basis-5/12 items-end">
                    <div class="flex flex-row flex-nowrap pb-2">
                        <div class="relative block justify-center pt-0">
                            <div class="flex flex-row text-xl p-2 justify-center" v-bind:style='{ backgroundColor : settings.theme_color_2.currency }'>
                                <div v-for="rate in exchange_rate" :key="rate" class="pr-4 pl-4 text-2xl border-r border-solid" v-bind:style='{ color : this.settings.theme_color_2.banner_text_color, borderColor: this.settings.theme_color.banner_text_color }'>
                                    {{ rate.symbol}} : {{ rate.forexsat }}
                                </div>
                            </div>
                        </div>
                        
                        <div class="flex flex-row flex-nowrap mt-0 ml-5 mr-2 relative cursor-pointer" v-tooltip.bottom="'Çıkış Yap'"
                            @click="router_push_to({
                                name: 'login',
                                params: {},
                                }
                            )" >
                            <div class="m-auto align-middle">
                                <svg class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" v-bind:style='{ color : this.settings.theme_color_2.banner_text_color }'>
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                </svg>
                            </div>
                            <div class="text-xl m-auto align-middle pl-2" v-bind:style='{ color : this.settings.theme_color_2.banner_text_color }'>
                                Çıkış Yap
                            </div>
                        </div>
                    </div>
                    <div class="relative justify-center float-right w-full">
                        <Topiconsmenu_2 class = ""/>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <header v-else-if="(settings.theme_design == 3)" class="hidden md:block fixed top-0 left-0 right-0 z-[1000] shadow-md shadow-gray-400" data-sticky="true">
        <div class="flex flex-col">
            <div class="flex pb-0 pt-1 border-b-1 border-white border-solid" v-bind:style='{ backgroundColor : settings.theme_color_3.banner }'>
                <div class="flex flex-row grid-cols-3 max-w-[1650px] mt-0 mb-0 mr-auto ml-auto w-full pl-9 pr-9 justify-between items-center">
                    <div class="relative left-2 basis-3/12">
                        <a @click="to_home_push" class="cursor-pointer"><img src="/images/logo-3.png" class="pb-5 max-h-[90%]"/></a>
                    </div>
                    <div class="relative w-full basis-5/12 justify-center">
                        <search_v3/>
                    </div>
                    <div class="flex flex-col basis-4/12 items-end">
                        <div class="flex flex-row flex-nowrap pb-2">
                            <div class="relative justify-center pt-0">
                                <div class="flex flex-row text-xl p-2 justify-center" v-bind:style='{ backgroundColor : settings.theme_color_3.currency }'>
                                    <div v-for="rate in exchange_rate" :key="rate" class="pr-4 pl-4 text-xl border-r border-solid" v-bind:style='{ color : this.settings.theme_color_3.banner_text_color, borderColor: this.settings.theme_color.banner_text_color }'>
                                        {{ rate.symbol}} : {{ rate.forexsat }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-row flex-nowrap mt-0 mb-1 ml-5 mr-2 relative cursor-pointer" v-tooltip.bottom="'Çıkış Yap'"
                                @click="router_push_to({
                                    name: 'login',
                                    params: {},
                                    }
                                )" >
                                <div class="m-auto align-middle">
                                    <svg class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" v-bind:style='{ color : this.settings.theme_color_3.banner_text_color }'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                    </svg>
                                </div>
                                <div class="text-xl m-auto align-middle pl-2" v-bind:style='{ color : this.settings.theme_color_3.banner_text_color }'>
                                    Çıkış Yap
                                </div>
                            </div>
                        </div>
                        <div class="relative justify-center float-right w-full">
                            <Topiconsmenu_3 class = ""/>
                        </div>
                    </div>
                </div>
            </div>
            <Subiconsmenu_3 class = ""/>
        </div>
    </header>
    <header class="block md:hidden fixed top-0 left-0 right-0 z-[1000] shadow-md shadow-gray-400" data-sticky="true">
        <div class="flex pb-4 pt-4 border-b-1 border-white border-solid" v-bind:style='{ backgroundColor : settings.theme_color.banner }'>
            <div class="flex flex-col grid-cols-3 max-w-[1650px] mt-0 mb-0 mr-auto ml-auto w-full pl-4 pr-4 justify-center items-start">
                <div class="flex flex-row justify-between w-full mb-1 items-center">
                    <div class="relative w-3/6 pr-4">
                        <a @click="to_home_push" class="cursor-pointer"><img src="/images/logo.png" class="max-h-[70%]"/></a>
                    </div>
                    <div class="relative w-1/6 pr-4">

                    </div>
                    <div class="relative w-2/6 justify-center items-center">
                        <div class="flex flex-col text-base border border-solid border-white rounded-xl p-2 justify-between w-full" v-bind:style='{ backgroundColor : settings.theme_color.currency, borderColor: this.settings.theme_color.banner_text_color }'>
                            <div v-for="rate in exchange_rate" :key="rate" v-bind:style='{ color : this.settings.theme_color.banner_text_color }'>
                                {{ rate.symbol}} : {{ rate.forexsat }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative w-full justify-center">
                    <search_v/>
                </div>
                <div class="relative justify-center w-[90%]">
                    <Topiconsmenu class = ""/>
                </div>
            </div>
        </div>
    </header>

    <!-- MODAL RECONCILIATION DIALOG B0X --------------------------------------------------------------->
    <div v-show="show_alert_reconciliation" class="fixed top-0 left-0 grid place-items-center w-screen h-screen backdrop-blur-sm bg-gray-500/50 z-[1051]">
        <div class="flex flex-col h-screen border border-gray-300 rounded-lg p-9 min-w-[50%] bg-white shadow-xl max-w-[98%] max-h-[98%] md:max-w-[65%] md:max-h-[85%]">

        <div id="modalheader" class="flex flex-col w-full mb-8 justify-between">
            <div class="float-left text-3xl font-bold pr-2">
                e-Mutabakat -> Cari Hesap Mutabakatı
            </div>
        </div>

        <div id="modalbody" class="w-full overflow-x-hidden h-[94%] md:h-full overflow-y-auto p-5 mt-0 mr-auto mb-5 ml-auto border border-solid border-gray-300">

            <div class="flex flex-row flex-nowrap pt-3 pb-10">
            <div class="flex flex-col">
                <div class="text-2xl md:text-3xl font-semibold pl-3 md:pl-6 pr-1 md:pr-3 pb-3">
                    Sayın {{ this.account_info.name }},
                </div>
                <div class="text-2xl md:text-2xl pl-3 md:pl-6 pr-1 md:pr-3">
                    Mali tablolarımızın {{ this.format_date(this.reconciliation_endingDate) }} tarihi itibariyle bağımsız denetimini denetçilerimiz {{ this.reconciliation_auditor_name }} tarafından yapılmaktadır.<br />
                    Şirketimiz nezdindeki Cari Hesabınızda <label class="font-semibold">{{ this.format_date(this.reconciliation_endingDate) }}</label> tarihi itibariyle <label class="font-semibold">{{ this.reconciliation_amount.toLocaleString() }} TL</label> <label class="text-red-600 font-semibold">{{ this.reconciliation_debtOrCredit }}</label> bakiyeniz bulunmaktadır.
                </div>
            </div>
            </div>
            <div class="flex flex-col md:flex-row text-center pt-3 pb-5 md:pt-0 w-full">
            <div class="mb-5 md:mb-0 md:mr-5 w-full md:w-1/2 border border-solid border-gray-400 rounded-2xl p-3">
                <div>
                <button type="submit" class="text-center pt-8 pb-8 pr-11 pl-11 text-3xl w-full text-white border-0 rounded-lg cursor-pointer transition-all bg-green-600 hover:bg-green-900 font-semibold leading-5" @click="reconciliation_action('accept')">Onayla (Mutabıkız)</button>
                </div>
                <div class="text-2xl md:text-3xl mt-5 w-full text-left">
                    Bakiyede mutabık iseniz tutar girişi yapmadan &quot;Onayla (Mutabıkız)&quot; butonuna basınız.
                </div>
            </div>
            <div class="flex flex-col md:ml-5 w-full md:w-1/2 border border-solid border-gray-400 rounded-2xl p-3">
                <div>
                <button type="submit" class="text-center pt-8 pb-8 pr-11 pl-11 text-3xl w-full text-white border-0 rounded-lg cursor-pointer transition-all bg-red-600 hover:bg-red-900 font-semibold leading-5" @click="reconciliation_action('decline')">Mutabık Değiliz</button>
                </div>
                <div class="mt-5 flex flex-col">
                    <div class="text-2xl md:text-3xl w-full text-left">
                        Bakiyede mutabık değilseniz aşağıdaki bilgi girişlerini yaparak &quot;Mutabık Değiliz&quot; butonuna basınız.
                    </div>
                    <div class="p-5 mt-5">
                        <div class="flex flex-col md:flex-row md:flex-nowrap mt-4">
                            <div class="flex flex-col w-full md:w-1/2 mr-3">
                                <div class="text-2xl md:text-3xl font-semibold text-left mb-1">
                                    Tutar:
                                </div>
                                <div class="flex flex-row flex-nowrap">
                                    <div class="mb-6 border border-blue-700 rounded w-full">
                                        <input type="text" name="customerAmount" v-model="customerAmount" autocomplete="false"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                        class="w-full text-2xl md:text-3xl border-1 bg-gray-100 p-4 text-right">
                                    </div>
                                    <div class="text-2xl md:text-3xl mt-4 pl-3">
                                        TL
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col w-full md:w-1/2 pl-0 md:pl-5">
                                <div class="flex flex-row flex-nowrap w-full mt-3">
                                    <div class="mr-3">
                                        <input type="radio" class="h-9 w-9 cursor-pointer" name="reconciliation_debt_credit" id="reconciliation_debt_B" v-model="reconciliation_debt_credit" value="A">
                                    </div>
                                    <div>
                                        <label for="reconciliation_debt_B" class="text-2xl md:text-3xl cursor-pointer">Borçluyum</label>
                                    </div>
                                </div>
                                <div class="flex flex-row flex-nowrap w-full mt-3">
                                    <div class="mr-3">
                                        <input type="radio" class="h-9 w-9 cursor-pointer" name="reconciliation_debt_credit" id="reconciliation_credit_A" v-model="reconciliation_debt_credit" value="B">
                                    </div>
                                    <div>
                                        <label for="reconciliation_credit_A" class="text-2xl md:text-3xl cursor-pointer">Alacaklıyım</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="flex flex-col md:flex-row text-center pt-5 md:pb-5 md:pt-0 w-full">
            <div class="mb-5 md:mb-0 md:mr-5 w-full md:w-1/2">
                <button type="submit" class="text-center pt-4 md:pt-8 pb-4 md:pb-8 pr-4 md:pr-11 pl-4 md:pl-11 text-3xl w-full text-white border-0 rounded-lg cursor-pointer transition-all bg-blue-600 hover:bg-blue-900 font-semibold leading-5" @click="reconciliation_action('reminder')">Daha Sonra Hatırlat (Yarın)</button>
            </div>
            <div class="mb-5 md:mb-0 md:ml-5 w-full md:w-1/2">
                <button type="submit" class="text-center pt-4 md:pt-8 pb-4 md:pb-8 pr-4 md:pr-11 pl-4 md:pl-11 text-3xl w-full text-white border-0 rounded-lg cursor-pointer transition-all bg-gray-500 hover:bg-gray-900 font-semibold leading-5" @click="exportCustomerData">Mutabakat cari hareketlerini indir</button>
            </div>
            </div>
            <div class="flex flex-row flex-nowrap pt-5 pb-5">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 fill-blue-800" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                    </svg>
                </div>
                <div class="text-xl md:text-2xl font-bold pl-6">
                    <ul class="list-disc pl-6">
                        <li>T.T.K. 94. maddesi gereği mutabakat veya itirazınızı <label class="font-semibold">{{ this.format_date(this.reconciliation_dueDate) }}</label> tarihine kadar bildirmediğiniz takdirde bakiyede mutabık sayılacağınızı bilginize sunarız.</li>
                        <li>Hata ve unutma müstesnadır.</li>
                    </ul>
                </div>
            </div>

        </div>

        </div>
    </div>

    <!-- MODAL ALERT ACCEPT --------------------------------------------------------------->
    <div v-show="show_alert_reconciliation_accept" class="fixed top-0 left-0 grid place-items-center w-screen h-screen backdrop-blur-sm bg-gray-500/50 z-[1051]">
        <div class="flex flex-col border border-gray-300 rounded-lg p-9 min-w-[50%] bg-white shadow-xl max-w-[98%] max-h-[98%] md:max-w-[65%] md:max-h-[65%]">

            <div id="modalheader" class="flex flex-row w-full mb-8 justify-between">
                <div class="float-left text-3xl font-semibold pr-2">
                    e-Mutabakat Bilgilendirmesi (Mutabıkız)
                </div>
                <div class="top-0 right-0">
                    <button @click="show_alert_reconciliation_accept=false;" class="top-0 right-0 bg-red-700 text-white h-10 w-10 rounded-full font-bold items-center text-center" title="Kapat (Esc)">X</button>
                </div>
            </div>

            <div id="modalbody" class="w-full overflow-x-hidden h-[80%] md:max-h-[400px] overflow-y-auto p-5 mt-0 mr-auto mb-5 ml-auto border border-solid border-gray-300">
                <div class="flex flex-row flex-nowrap pt-2 pb-3 md:pb-6">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 fill-blue-800" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                        </svg>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex flex-col mb-1 pl-6">
                            <div class="text-2xl md:text-3xl font-semibold text-left mb-1">
                                Adınız Soyadınız:
                            </div>
                            <div>
                                <div class="border border-blue-700 rounded w-full">
                                    <input type="text" name="approverNameSurname" v-model="approverNameSurname" autocomplete="false" class="w-full text-2xl md:text-3xl border-1 bg-gray-100 p-4">
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col mb-8 pl-6">
                            <div class="text-2xl md:text-3xl font-semibold mr-3 mt-4 text-left mb-1">
                                Mesajınız:
                            </div>
                            <div>
                                <div class="border border-blue-700 rounded w-full">
                                    <input type="text" name="approverNotes" v-model="approverNotes" autocomplete="false" class="w-full text-2xl md:text-3xl border-1 bg-gray-100 p-4">
                                </div>
                            </div>
                        </div>
                        <div class="text-2xl md:text-3xl font-bold pl-6 pr-3 pb-3 md:pb-10">
                            Bakiyede mutabık olduğunuz bilgisi kayıt altına alınacaktır.<br />
                            İşleme devam etmek istiyor musunuz?
                        </div>
                        <div class="flex flex-col md:flex-row text-center pt-5 md:pb-5 md:pt-0 w-full pl-6">
                            <div class="mb-5 md:mb-0 md:mr-5">
                                <button type="submit" class="text-center pt-8 pb-8 pr-14 pl-14 text-3xl w-11/12 md:w-full text-white border-0 rounded-lg cursor-pointer transition-all bg-green-600 hover:bg-green-900 font-semibold leading-5" @click="reconciliation_action_save()">Devam Et</button>
                            </div>
                            <div class="md:ml-5">
                                <button type="submit" class="text-center pt-8 pb-8 pr-11 pl-11 text-3xl w-11/12 md:w-full text-white border-0 rounded-lg cursor-pointer transition-all bg-red-600 hover:bg-red-900 font-semibold leading-5" @click="show_alert_reconciliation_accept=false">Vazgeç</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL ALERT DECLINE --------------------------------------------------------------->
    <div v-show="show_alert_reconciliation_decline" class="fixed top-0 left-0 grid place-items-center w-screen h-screen backdrop-blur-sm bg-gray-500/50 z-[1051]">
        <div class="flex flex-col border border-gray-300 rounded-lg p-9 min-w-[50%] bg-white shadow-xl max-w-[98%] max-h-[98%] md:max-w-[65%] md:max-h-[65%]">

            <div id="modalheader" class="flex flex-row w-full mb-8 justify-between">
                <div class="float-left text-3xl font-semibold pr-2">
                    e-Mutabakat Bilgilendirmesi (Mutabık Değiliz)
                </div>
                <div class="top-0 right-0">
                    <button @click="show_alert_reconciliation_decline=false;" class="top-0 right-0 bg-red-700 text-white h-10 w-10 rounded-full font-bold items-center text-center" title="Kapat (Esc)">X</button>
                </div>
            </div>

            <div id="modalbody" class="w-full overflow-x-hidden h-[80%] md:max-h-[400px] overflow-y-auto p-5 mt-0 mr-auto mb-5 ml-auto border border-solid border-gray-300">
                <div class="flex flex-row flex-nowrap pt-4 pb-10">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 fill-blue-800" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                        </svg>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex flex-col mb-1 pl-6">
                            <div class="text-2xl md:text-3xl font-semibold text-left mb-1">
                                Adınız Soyadınız:
                            </div>
                            <div>
                                <div class="border border-blue-700 rounded w-full">
                                    <input type="text" name="rejectingNameSurname" v-model="rejectingNameSurname" autocomplete="false" class="w-full text-2xl md:text-3xl border-1 bg-gray-100 p-4">
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col mb-8 pl-6">
                            <div class="text-2xl md:text-3xl font-semibold mr-3 mt-4 text-left mb-1">
                                Mesajınız:
                            </div>
                            <div>
                                <div class="border border-blue-700 rounded w-full">
                                    <input type="text" name="rejectingNotes" v-model="rejectingNotes" autocomplete="false" class="w-full text-2xl md:text-3xl border-1 bg-gray-100 p-4">
                                </div>
                            </div>
                        </div>
                        <div class="text-2xl md:text-3xl font-bold pl-6 pr-3 pb-3 md:pb-10">
                            Bakiyede mutabık olmadığınız ve yeni mutabakat bilgileriniz kayıt altına alınacaktır.<br />
                            İşleme devam etmek istiyor musunuz?
                        </div>
                        <div class="flex flex-col md:flex-row text-center pt-5 md:pb-5 md:pt-0 w-full pl-6">
                            <div class="mb-5 md:mb-0 md:mr-5">
                                <button type="submit" class="text-center pt-8 pb-8 pr-14 pl-14 text-3xl w-11/12 md:w-full text-white border-0 rounded-lg cursor-pointer transition-all bg-green-600 hover:bg-green-900 font-semibold leading-5" @click="reconciliation_action_save()">Devam Et</button>
                            </div>
                            <div class="md:ml-5">
                                <button type="submit" class="text-center pt-8 pb-8 pr-11 pl-11 text-3xl w-11/12 md:w-full text-white border-0 rounded-lg cursor-pointer transition-all bg-red-600 hover:bg-red-900 font-semibold leading-5" @click="show_alert_reconciliation_decline=false">Vazgeç</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

</template>


<script>
/* 
<!-- MENU LIST -->

<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
</svg>

<!-- CHARTS -->

<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

<!-- heart -->
<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
</svg>

<!-- CART  -->

<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
</svg>


<!-- USER -->

<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
</svg>



        
            <div class="header__right">
                <div class="header__actions"><a class="header__extra" href="#">
                    
                    <!-- <i class="icon-chart-bars"></i> -->
                    <svg class="h-10 w-10 hover:fill-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /> 
                    </svg>
                    
                    <span><i>0</i></span></a><a class="header__extra" href="#">
                        
                        <!-- <i class="icon-heart"></i> -->
                        <svg class="h-10 w-10 hover:fill-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>

                        
                        <span><i>0</i></span></a>
                    
                    
                    <div @click="this.$router.push('/cart')" class="ps-cart--mini"><a class="header__extra" href="#">
                        
                        
                        <!-- <i class="icon-bag2"></i> -->

                        <svg class="h-10 w-10 hover:fill-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                        </svg>

                        
                        
                        <span><i>0</i></span></a>
                        <div class="ps-cart__content">
                            <div class="ps-cart__items">
                                <div class="ps-product--cart-mobile">
                                    <div class="ps-product__thumbnail"><a href="#"><img src="img/products/clothing/7.jpg" alt=""></a></div>
                                    <div class="ps-product__content"><a class="ps-product__remove" href="#"><i class="icon-cross"></i></a><a href="product-default.html">MVMTH Classical Leather Watch In Black</a>
                                        <p><strong>Sold by:</strong> YOUNG SHOP</p><small>1 x $59.99</small>
                                    </div>
                                </div>
                                <div class="ps-product--cart-mobile">
                                    <div class="ps-product__thumbnail"><a href="#"><img src="img/products/clothing/5.jpg" alt=""></a></div>
                                    <div class="ps-product__content"><a class="ps-product__remove" href="#"><i class="icon-cross"></i></a><a href="product-default.html">Sleeve Linen Blend Caro Pane Shirt</a>
                                        <p><strong>Sold by:</strong> YOUNG SHOP</p><small>1 x $59.99</small>
                                    </div>
                                </div>
                            </div>
                            <div class="ps-cart__footer">
                                <h3>Sub Total:<strong>$59.99</strong></h3>
                                <figure><a class="ps-btn" href="shopping-cart.html">View Cart</a><a class="ps-btn" href="checkout.html">Checkout</a></figure>
                            </div>
                        </div>
                    </div>





                    <div class="ps-block--user-header cursor-pointer" @click="this.$router.push('/dash')">
                        <div class="ps-block__left"> 
                            <!--<i class="icon-user"> </i>--> 
                            <svg class="h-10 w-10 hover:fill-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>

                        </div>
                               <!-- <div class="ps-block__right"><a href="my-account.html">Login</a><a href="my-account.html">Register</a></div> -->
                    </div>



                </div>
            </div>







            <div class="header__left">
                <div class="menu--product-categories">
                    <div class="menu__toggle">
                        
                        <!-- <i class="icon-menu"></i> --->
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                        </svg>
                        
                        
                        <span> Shop by Department</span></div>
                    <div class="menu__content">
                        <ul class="menu--dropdown">
                            <li><a href="#"><i class="icon-star"></i> Hot Promotions</a>
                            </li>
                            <li class="menu-item-has-children has-mega-menu"><a href="#"><i class="icon-laundry"></i> Consumer Electronic</a>
                                <div class="mega-menu">
                                    <div class="mega-menu__column">
                                        <h4>Electronic</h4>
                                        <mega-listv />
                                    </div>
                                    <div class="mega-menu__column">
                                        <h4>Accessories &amp; Parts</h4>
                                        <ul class="mega-menu__list">
                                            <li><a href="#">Digital Cables</a>
                                            </li>
                                            <li><a href="#">Audio &amp; Video Cables</a>
                                            </li>
                                            <li><a href="#">Batteries</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li><a href="#"><i class="icon-shirt"></i> Clothing &amp; Apparel</a>
                            </li>
                            <li><a href="#"><i class="icon-lampshade"></i> Home, Garden &amp; Kitchen</a>
                            </li>
                            <li><a href="#"><i class="icon-heart-pulse"></i> Health &amp; Beauty</a>
                            </li>
                            <li><a href="#"><i class="icon-diamond2"></i> Yewelry &amp; Watches</a>
                            </li>
                            <li class="menu-item-has-children has-mega-menu"><a href="#"><i class="icon-desktop"></i> Computer &amp; Technology</a>
                                <div class="mega-menu">
                                    <div class="mega-menu__column">
                                        <h4>Computer &amp; Technologies</h4>
                                        <ul class="mega-menu__list">
                                            <li><a href="#">Computer &amp; Tablets</a>
                                            </li>
                                            <li><a href="#">Laptop</a>
                                            </li>
                                            <li><a href="#">Monitors</a>
                                            </li>
                                            <li><a href="#">Networking</a>
                                            </li>
                                            <li><a href="#">Drive &amp; Storages</a>
                                            </li>
                                            <li><a href="#">Computer Components</a>
                                            </li>
                                            <li><a href="#">Security &amp; Protection</a>
                                            </li>
                                            <li><a href="#">Gaming Laptop</a>
                                            </li>
                                            <li><a href="#">Accessories</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li><a href="#"><i class="icon-baby-bottle"></i> Babies &amp; Moms</a>
                            </li>
                            <li><a href="#"><i class="icon-baseball"></i> Sport &amp; Outdoor</a>
                            </li>
                            <li><a href="#"><i class="icon-smartphone"></i> Phones &amp; Accessories</a>
                            </li>
                            <li><a href="#"><i class="icon-book2"></i> Books &amp; Office</a>
                            </li>
                            <li><a href="#"><i class="icon-car-siren"></i> Cars &amp; Motocycles</a>
                            </li>
                            <li><a href="#"><i class="icon-wrench"></i> Home Improments</a>
                            </li>
                            <li><a href="#"><i class="icon-tag"></i> Vouchers &amp; Services</a>
                            </li>
                        </ul>
                    </div>
                </div><a class="ps-logo" href="/dashboard"><img src="img/logo_light.png" alt="Ana Sayfa"></a>
            </div>

        

*/
import Topiconsmenu from './header_iconsmenu.vue'
import Topiconsmenu_2 from './header_iconsmenu_2.vue'
import Topiconsmenu_3 from './header_iconsmenu_3.vue'
import Subiconsmenu_3 from './header_iconsmenu_3_sub.vue'
import MegaLisV from '../mega_menu.vue'
import SearchV from '../search_v.vue'
import SearchV_2 from '../search_v2.vue'
import SearchV_3 from '../search_v3.vue'
import NavigaV from '../navigation_v.vue'
import { mapActions, mapGetters } from 'vuex'
import { excelParser } from "../../views/excel-parser";

export default {
    name: 'Header',
    data(){
        return {
            show_alert_reconciliation   : false,
            show_alert_reconciliation_accept : false,
            show_alert_reconciliation_decline: false,
            reconciliation_action_type  : "",
            reconciliation_id           : null,
            reconciliation_endingDate   : new Date(),
            reconciliation_dueDate      : new Date(),
            reconciliation_amount       : 0,
            reconciliation_debtOrCredit : "(B/A)",
            reconciliation_auditor_name : "",
        }
    },
    async created() {

        const ret_reconciliations = await this.$store.dispatch('get_reconciliations_info', {'reconciliation_token': ''})

        if ((ret_reconciliations != {}) && (ret_reconciliations != null)){

            this.reconciliation_id              = ret_reconciliations.headerId
            this.reconciliation_endingDate      = ret_reconciliations.endingDate;
            this.reconciliation_amount          = Math.abs(ret_reconciliations.amount);
            this.reconciliation_debtOrCredit    = (ret_reconciliations.debtOrCredit == 'A') ? "Borç" : "Alacak";
            this.reconciliation_dueDate         = ret_reconciliations.dueDate;
            this.reconciliation_auditor_name    = ret_reconciliations.auditor_name;
            this.show_alert_reconciliation      = true;

            // this.$toast.error(ret_reconciliations, {
            //         position: "top",
            //         duration : 10000,
            //         enableHtml: true
            //     })
        }
    },
    components:{
        'mega_listv' : MegaLisV,
        'search_v'   : SearchV,
        'search_v2'   : SearchV_2,
        'search_v3'   : SearchV_3,
        'navigation_v': NavigaV,
        'Topiconsmenu' : Topiconsmenu,
        'Topiconsmenu_2' : Topiconsmenu_2,
        'Topiconsmenu_3' : Topiconsmenu_3,
        'Subiconsmenu_3' : Subiconsmenu_3,
    },
    computed: 
    {
        ...mapGetters(
            {
                exchange_rate   : 'get_exchanges',
                account_info    : 'get_account', // B 17.11.2022 guncel tags bilgileri aliniyor, 09.03.2023 - Sistem guncellenme kontrolu, 10.03.2023 - Gunluk giris tarih kontrolu
                settings        : 'get_settings',
                outofdate_total : 'get_outofdate_total' //23.06.2023 - Nakde dusen borc toplami
            }
        )
    },

    mounted(){
        if ((this.outofdate_total > 0) && (this.settings.outofdate_total_info)){
            const message_str = `<font style="font-size: 16px; padding-top: 10px; padding-bottom: 10px;"><font style="font-weight: 600;">${this.outofdate_total.toLocaleString("tr-TR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} TL</font> tutarında vadesi geçmiş borcunuz bulunmaktadır !</font>`
            this.$toast.error(message_str, {
                position: "top",
                duration : 10000,
                enableHtml: true
            })
            this.$store.dispatch('commit_outofdate_total', 0 )
        }
    },

    methods:
    {
        ...mapActions(
            ['get_fast_cari']
        ),
        async to_home_push()
        {
            this.$router.push(
                {name: 'home'}
            )

            if (this.account_info.lastlogindate != undefined){ // 10.03.2023 - Gunluk giris tarih kontrolu
              const controlToday    = new Date();
              const controlDate     = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
              const UserDate        = new Date(this.account_info.lastlogindate);
              const controlUserDate = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
              if (Number(controlDate) != Number(controlUserDate)){
                this.$router.push(
                    {name: 'login'}
                )
              } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }
             }
            } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }

            }

        },

        router_push_to( locationNparams ){
            this.$router.push({
                name:locationNparams.name,
                params: locationNparams.params
            })

            // console.log('localtion params to chejc undefined')
            // console.dir(
            //     locationNparams
            // )
            if(locationNparams.name == 'login'){
                // console.log('login reseting state')
                this.$store.dispatch('reset_state_1',{});
            }
            if(locationNparams.name == 'dash' && locationNparams.params.activetableroute == 'commercialtransactions')this.get_fast_cari();
            if(locationNparams.name == 'payment')this.get_fast_cari();

            // B 17.11.2022 guncel tags bilgileri aliniyor
            if ((locationNparams.name == 'dash') || (locationNparams.name == 'cart' ) || (locationNparams.params.activetableroute == 'favorites')){
                this.get_account_info()
            }
            // E 17.11.2022 guncel tags bilgileri aliniyor
            // B 30.11.2022  -   Favorilerim sayfa duzenlemesi
            if (locationNparams.params.activetableroute == 'favorites'){
                    this.req_detailed_faves()
            }
            // E 30.11.2022  -   Favorilerim sayfa duzenlemesi
        },

        async exportCustomerData() {

            const dispatchpayload = {
                cust_no           : this.account_info.no,
                endingDate        : this.reconciliation_endingDate,
            }

            // console.log("dispatchpayload:" + JSON.stringify(dispatchpayload))

            const get_custdata = await this.$store.dispatch('cust_reconciliation_ledger_list', dispatchpayload)

            const new_getcustdatalist = await get_custdata.map((i) => {
            return {
                'İşlem Tarihi'     : this.format_ms_to_date(i.date),
                'Vade Tarihi'      : this.format_ms_to_date(i.duedate),
                'Belge No'         : i.docno,
                'İşlem Türü'       : i.transactiontype,
                'Açıklama'         : i.descr,
                'Borç'             : this.format_tl(i.debt),
                'Alacak'           : this.format_tl(i.credit),
                'Kalan Bakiye'     : this.format_tl(i.remaining),
                }
            })

            excelParser().exportDataFromJSON(new_getcustdatalist, `Cari Hareket Listesi - ${new Date().toLocaleDateString("tr-TR")}`, null);

            this.$toast.info('Cari hareketlerinizin indirilme işlemini tarayıcınızdan takip ediniz.', {
                    position: "top",
                    duration : 10000
                })
        },

        format_date(date_value) {
          var newdate = "..."
          if (date_value != null){
            const date = new Date(date_value);
            newdate = date.toLocaleDateString("tr-TR")
          }
          return newdate
        },

        format_ms_to_date(date_value_in) {
            const date = new Date(Number(date_value_in))
            const new_date_str = date.toLocaleDateString("tr-TR")
            return new_date_str
        },

        async reconciliation_action(data_in){

            if (data_in == 'reminder'){

                var dispatchpayload = {
                    action_type       : data_in,
                    cust_no           : this.account_info.no,
                    reconciliation_id : this.reconciliation_id,
                }

                const start_reconciliation = await this.$store.dispatch('cust_reconciliation_actions', dispatchpayload)

                this.$toast.success('Mutabakat hatırlatmanız başarıyla kaydedilmiştir.', {
                    position: "top",
                    duration : 5000
                })

                this.show_alert_reconciliation = false;
                return
            }

            if (data_in == 'decline'){
                if ((this.customerAmount == undefined) || (this.customerAmount == "") || (this.customerAmount == null)){
                    this.$toast.error('Mutabık olduğunuz tutarı yazmadınız !', {
                        position: "top-right",
                        duration : 5000
                    })
                    return
                }
                if (this.reconciliation_debt_credit == undefined){
                    this.$toast.error('Borç/Alacak durumunu seçmediniz !', {
                        position: "top-right",
                        duration : 5000
                    })
                    return
                }
                this.show_alert_reconciliation_decline = true

            } else {
                this.show_alert_reconciliation_accept = true
            }

            this.reconciliation_action_type = data_in
        },

        async reconciliation_action_save(){

            var str_msg = "";
            var data_in = this.reconciliation_action_type

            if (data_in == 'accept'){

                if ((this.approverNameSurname == undefined) || (this.approverNameSurname == "") || (this.approverNameSurname == null)){
                    this.$toast.error('Adınızı ve soyadınızı yazmadınız !', {
                        position: "top-center",
                        duration : 5000
                    })
                    return
                }

                str_msg = 'Mutabakat kaydınız başarıyla tamamlanmıştır.'

                var dispatchpayload = {
                    action_type       : data_in,
                    customerAmount    : this.reconciliation_amount,
                    customerDebtCredit: (this.reconciliation_debtOrCredit == 'Borç') ? "A" : "B",
                    cust_no           : this.account_info.no,
                    reconciliation_id : this.reconciliation_id,
                    approverNameSurname: this.approverNameSurname,
                    approverNotes     : this.approverNotes || "",
                    accessType        : 'B2B'

                }

            } else if (data_in == 'decline'){
                
                if ((this.rejectingNameSurname == undefined) || (this.rejectingNameSurname == "") || (this.rejectingNameSurname == null)){
                    this.$toast.error('Adınızı ve soyadınızı yazmadınız !', {
                        position: "top-center",
                        duration : 5000
                    })
                    return
                }

                str_msg = 'Mutabakat bilgileriniz başarıyla tamamlanmıştır.'
                
                dispatchpayload = {
                    action_type         : data_in,
                    customerAmount      : this.customerAmount,
                    customerDebtCredit  : this.reconciliation_debt_credit,
                    cust_no             : this.account_info.no,
                    reconciliation_id   : this.reconciliation_id,
                    approverNameSurname : this.rejectingNameSurname,
                    approverNotes       : this.rejectingNotes || "",
                    accessType          : 'B2B'
                }
                
            }

            // console.log("dispatchpayload:" + JSON.stringify(dispatchpayload))

            const start_reconciliation = await this.$store.dispatch('cust_reconciliation_actions', dispatchpayload)

            this.$toast.success(str_msg, {
              position: "top",
              duration : 5000
            })

            this.show_alert_reconciliation_accept   = false
            this.show_alert_reconciliation_decline  = false
            this.show_alert_reconciliation          = false
        }
        
    }
}
</script>
