<template>
      <a class="mega-menu__list" v-for= "menu_list in menu_list_V" :key="menu_list.name_1" :href= "'/catogory/' + menu_list.name_1" > {{ menu_list.name_1  }}</a>
</template>


<script>
export default {
    data(){
    },
    computed:{
        menu_list_V(){
            return this.$store.getters.getItemsMenu
        }
    }
}


/* 

      <div class="ps-panel--search-result">
        <div class="ps-panel__content">
          <div class="ps-product ps-product--wide ps-product--search-result">
            <div class="ps-product__thumbnail">
              <a href="product-default.html"
                ><img src="img/products/arrivals/1.jpg" alt=""
              /></a>
            </div>
            <div class="ps-product__content">
              <a class="ps-product__title" href="product-default.html"
                >Apple iPhone Retina 6s Plus 32GB</a
              >
              <div class="ps-product__rating">
                <select class="ps-rating" data-read-only="true">
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="2">5</option></select
                ><span></span>
              </div>
              <p class="ps-product__price">$990.50</p>
            </div>
          </div>
          <div class="ps-product ps-product--wide ps-product--search-result">
            <div class="ps-product__thumbnail">
              <a href="product-default.html"
                ><img src="img/products/arrivals/1.jpg" alt=""
              /></a>
            </div>
            <div class="ps-product__content">
              <a class="ps-product__title" href="product-default.html"
                >Apple iPhone Retina 6s Plus 64GB</a
              >
              <div class="ps-product__rating">
                <select class="ps-rating" data-read-only="true">
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="2">5</option></select
                ><span></span>
              </div>
              <p class="ps-product__price">$1120.50</p>
            </div>
          </div>
          <div class="ps-product ps-product--wide ps-product--search-result">
            <div class="ps-product__thumbnail">
              <a href="product-default.html"
                ><img src="img/products/arrivals/1.jpg" alt=""
              /></a>
            </div>
            <div class="ps-product__content">
              <a class="ps-product__title" href="product-default.html"
                >Apple iPhone Retina 6s Plus 128GB</a
              >
              <div class="ps-product__rating">
                <select class="ps-rating" data-read-only="true">
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="2">5</option></select
                ><span></span>
              </div>
              <p class="ps-product__price">$1220.50</p>
            </div>
          </div>
          <div class="ps-product ps-product--wide ps-product--search-result">
            <div class="ps-product__thumbnail">
              <a href="product-default.html"
                ><img src="img/products/arrivals/2.jpg" alt=""
              /></a>
            </div>
            <div class="ps-product__content">
              <a class="ps-product__title" href="product-default.html"
                >Marshall Kilburn Portable Wireless Speaker</a
              >
              <div class="ps-product__rating">
                <select class="ps-rating" data-read-only="true">
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="2">5</option></select
                ><span>01</span>
              </div>
              <p class="ps-product__price">$36.78 – $56.99</p>
            </div>
          </div>
          <div class="ps-product ps-product--wide ps-product--search-result">
            <div class="ps-product__thumbnail">
              <a href="product-default.html"
                ><img src="img/products/arrivals/3.jpg" alt=""
              /></a>
            </div>
            <div class="ps-product__content">
              <a class="ps-product__title" href="product-default.html"
                >Herschel Leather Duffle Bag In Brown Color</a
              >
              <div class="ps-product__rating">
                <select class="ps-rating" data-read-only="true">
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="2">5</option></select
                ><span>02</span>
              </div>
              <p class="ps-product__price">$125.30</p>
            </div>
          </div>
          <div class="ps-product ps-product--wide ps-product--search-result">
            <div class="ps-product__thumbnail">
              <a href="product-default.html"
                ><img src="img/products/arrivals/4.jpg" alt=""
              /></a>
            </div>
            <div class="ps-product__content">
              <a class="ps-product__title" href="product-default.html"
                >Xbox One Wireless Controller Black Color</a
              >
              <div class="ps-product__rating">
                <select class="ps-rating" data-read-only="true">
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="2">5</option></select
                ><span>02</span>
              </div>
              <p class="ps-product__price">$55.30</p>
            </div>
          </div>
          <div class="ps-product ps-product--wide ps-product--search-result">
            <div class="ps-product__thumbnail">
              <a href="product-default.html"
                ><img src="img/products/arrivals/5.jpg" alt=""
              /></a>
            </div>
            <div class="ps-product__content">
              <a class="ps-product__title" href="product-default.html"
                >Grand Slam Indoor Of Show Jumping Novel</a
              >
              <div class="ps-product__rating">
                <select class="ps-rating" data-read-only="true">
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="2">5</option></select
                ><span>02</span>
              </div>
              <p class="ps-product__price sale">$41.27 <del>$52.99 </del></p>
            </div>
          </div>
          <div class="ps-product ps-product--wide ps-product--search-result">
            <div class="ps-product__thumbnail">
              <a href="product-default.html"
                ><img src="img/products/arrivals/6.jpg" alt=""
              /></a>
            </div>
            <div class="ps-product__content">
              <a class="ps-product__title" href="product-default.html"
                >Sound Intone I65 Earphone White Version</a
              >
              <div class="ps-product__rating">
                <select class="ps-rating" data-read-only="true">
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="2">5</option></select
                ><span>01</span>
              </div>
              <p class="ps-product__price sale">$41.27 <del>$62.39 </del></p>
            </div>
          </div>
        </div>
        <div class="ps-panel__footer text-center">
          <a href="shop-default.html">See all results</a>
        </div>
      </div>



*/
</script>