<template>
    
<div class="flex flex-col">
    <div class="flex content-center justify-center md:justify-between items-start md:items-center" v-bind:style='{ backgroundColor : settings.theme_color_3.bannermenubg }'>

        <div class="flex flex-row flex-nowrap max-w-[1650px] mt-0 mb-0 mr-auto ml-auto w-full">
            
            <div :class="'border-r border-l border-b-4 border-b-[#0e288e] hover:border-b-0 hover:border-t-4 border-t-blue-400 border-solid border-r-white border-l-white p-1.5 md:p-0 mt-5 md:mt-0 mb-0 ml-4 md:ml-0 mr-3 md:mr-0 inline-block items-center cursor-pointer bg-['+this.settings.theme_color_3.bannermenutablebg+'] hover:bg-['+this.settings.theme_color_3.bannermenubg+']'"
                @click="router_push_to({
                    name: 'home',
                    params: { },
                    }
                )">
                <div class="flex flex-row flex-nowrap p-4 min-w-[120px] relative">
                    <div>
                        <!-- HOME ICON  -->
                        <svg class="h-10 w-10 md:h-10 md:w-10 stroke-white hover:fill-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                    </div>
                    <div class="text-xl pl-2 pt-2 pb-1.5 font-semibold text-white text-center">
                        Ana Sayfa
                    </div>
                </div>
            </div>

            <div :class="'border-r border-l border-b-4 border-b-[#0e288e] hover:border-b-0 hover:border-t-4 border-t-blue-400 border-solid border-r-white border-l-white p-1.5 md:p-0 mt-5 md:mt-0 mb-0 ml-4 md:ml-0 mr-3 md:mr-0 inline-block items-center cursor-pointer bg-['+this.settings.theme_color_3.bannermenutablebg+'] hover:bg-['+this.settings.theme_color_3.bannermenubg+']'"
                @click="router_push_to({
                    name: 'dash',
                    params: {
                            activetableroute: 'orders'
                        },
                    }
                )">
                <div class="flex flex-row flex-nowrap p-4 min-w-[140px] relative">
                    <div>
                        <!-- ORDER ICON  -->
                        <svg class="h-10 w-10 md:h-10 md:w-10 stroke-white hover:fill-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                        </svg>
                    </div>
                    <div class="text-xl pl-2 pt-2 pb-1.5 font-semibold text-white text-center">
                        Siparişlerim
                    </div>
                </div>
            </div>

            <div :class="'border-r border-l border-b-4 border-b-[#0e288e] hover:border-b-0 hover:border-t-4 border-t-blue-400 border-solid border-r-white border-l-white p-1.5 md:p-0 mt-5 md:mt-0 mb-0 ml-4 md:ml-0 mr-3 md:mr-0 inline-block items-center cursor-pointer bg-['+this.settings.theme_color_3.bannermenutablebg+'] hover:bg-['+this.settings.theme_color_3.bannermenubg+']'"
                @click="router_push_to({
                    name:'returncart',
                    params: {
                        activetableroute: 'returnlist'
                    }
                })"> 

                <div class="flex flex-row flex-nowrap p-4 min-w-[160px] relative">
                    <div>
                        <span class="absolute bottom-10 right-[1px] flex justify-center items-center align-top w-8 h-8 text-xl font-bold text-white rounded-full z-20" v-bind:style='{ backgroundColor : this.settings.theme_color_3.count }'> {{ return_cart_count }} </span>

                        <svg class="h-10 w-10 md:h-10 md:w-10 stroke-white hover:fill-green-300 text-white hover:text-black" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z" />
                        </svg>
                    </div>
                    <div class="text-xl pl-2 pt-2 pb-1.5 font-semibold text-white text-center">
                        İade İşlemleri
                    </div>
                </div>
            </div>

            <!-- CASH -->
            <div :class="'border-r border-l border-b-4 border-b-[#0e288e] hover:border-b-0 hover:border-t-4 border-t-blue-400 border-solid border-r-white border-l-white p-1.5 md:p-0 mt-5 md:mt-0 mb-0 ml-4 md:ml-0 mr-3 md:mr-0 inline-block items-center cursor-pointer bg-['+this.settings.theme_color_3.bannermenutablebg+'] hover:bg-['+this.settings.theme_color_3.bannermenubg+']'"
                @click="router_push_to({
                    name:'payment',
                    params: {
                        activetableroute: 'orders'
                    }
                })"
                > 

                <div class="flex flex-row flex-nowrap p-4 min-w-[140px] relative">
                    <div>
                        <svg class="h-10 w-10 md:h-10 md:w-10 stroke-white hover:fill-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                        <!-- END CASH END -->
                    </div>
                    <div class="text-xl pl-2 pt-2 pb-1.5 font-semibold text-white text-center">
                        Ödeme Yap
                    </div>
                </div>
            </div>

            <!-- CHARTS ICON  -->
            <div :class="'border-r border-l border-b-4 border-b-[#0e288e] hover:border-b-0 hover:border-t-4 border-t-blue-400 border-solid border-r-white border-l-white p-1.5 md:p-0 mt-5 md:mt-0 mb-0 ml-4 md:ml-0 mr-3 md:mr-0 inline-block items-center cursor-pointer bg-['+this.settings.theme_color_3.bannermenutablebg+'] hover:bg-['+this.settings.theme_color_3.bannermenubg+']'"
            @click="router_push_to({
                    name:'dash',
                    params: {
                        activetableroute: 'commercialtransactions'
                    }
                })">
                <div class="flex flex-row flex-nowrap p-4 min-w-[160px] relative">
                    <div>
                        <svg class="h-10 w-10 md:h-10 md:w-10 stroke-white hover:fill-teal-600 hover:stoke-red-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /> 
                        </svg>
                    </div>
                    <div class="text-xl pl-2 pt-2 pb-1.5 font-semibold text-white text-center">
                        Cari Hareketler
                    </div>
                </div>

            </div>


            <div :class="'border-r border-l border-b-4 border-b-[#0e288e] hover:border-b-0 hover:border-t-4 border-t-blue-400 border-solid border-r-white border-l-white p-1.5 md:p-0 mt-5 md:mt-0 mb-0 ml-4 md:ml-0 mr-3 md:mr-0 inline-block items-center cursor-pointer bg-['+this.settings.theme_color_3.bannermenutablebg+'] hover:bg-['+this.settings.theme_color_3.bannermenubg+']'"
                @click="router_push_to({
                    name: 'dash',
                        params: {
                            activetableroute: 'islem_listesi'
                        },
                    }
                )" >
                <div class="flex flex-row flex-nowrap p-4 min-w-[140px] relative">
                    <div>
                        <svg class="h-10 w-10 md:h-10 md:w-10 stroke-white hover:fill-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                        </svg>
                    </div>
                    <div class="text-xl pl-2 pt-2 pb-1.5 font-semibold text-white text-center">
                        İşlem Listesi
                    </div>
                </div>
            </div>

            <div class="font-bold text-xl md:text-2xl w-full text-center m-auto" v-bind:style='{ color : this.settings.theme_color_3.bannermenutext }'>
                {{ this.account_info.name }}
            </div>

        </div>
    </div>
</div>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    methods: {
        ...mapActions(
            ['get_fast_cari']
            
        ),

        router_push_to( locationNparams ){
            this.$router.push({
                name:locationNparams.name,
                params: locationNparams.params
            })

            if(locationNparams.name == 'dash' && locationNparams.params.activetableroute == 'commercialtransactions')this.get_fast_cari();
            if(locationNparams.name == 'payment')this.get_fast_cari();

            // B 17.11.2022 guncel tags bilgileri aliniyor
            if ((locationNparams.name == 'dash') || (locationNparams.params.activetableroute == 'favorites')){
                this.get_account_info()
            }
            // E 17.11.2022 guncel tags bilgileri aliniyor
        },

        async get_account_info(){

            if (this.account_info.lastlogindate != undefined){ // 10.03.2023 - Gunluk giris tarih kontrolu
              const controlToday    = new Date();
              const controlDate     = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
              const UserDate        = new Date(this.account_info.lastlogindate);
              const controlUserDate = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
              if (Number(controlDate) != Number(controlUserDate)){
                this.$router.push(
                    {name: 'login'}
                )
              } else {// 09.03.2023 - Sistem guncellenme kontrolu 
                    const system_status_info =  await this.$store.dispatch('system_control')
                    .then( (res) => {
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )

                    if (!system_status_info){
                        this.$router.push(
                            {name: 'login'}
                        )
                        // console.log(system_status_info)
                        // console.log("this.account_info.system_status:" + this.account_info.system_status)

                    } else { // 17.11.2022 guncel tags bilgileri aliniyor
                        const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                        .then( (res) => {
                            //console.log("Kullanıcı bulundu !")
                            return res
                        })
                        .catch(
                            (err)=>{
                                console.log(err)
                            }
                        )
                    }
              }
            } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }
            }
        },
    },

    computed: {
        ...mapGetters({
            account_info        : 'get_account',
            return_cart_info    : 'get_account_return_cart_items',
            settings            : 'get_settings',
        }),

        return_cart_count () {
            if (this.return_cart_info == undefined){
                return 0
            } else {
                var retthis = 0;
                for (let i=0 ; i < this.return_cart_info.length; i++){
                    if (this.return_cart_info[i].qty != undefined){
                        retthis = retthis + this.return_cart_info[i].qty;
                    }
                }
                // const retthis = this.return_cart_info.reduce((a,b) => { return a.qty + b.qty, 0})
                return retthis
            }
        },
        notif_count() {
            return (this.account_info.stock_notify == undefined) ? 0 : this.account_info.stock_notify.length //03.12.2022 - stock_notify degeri yok ise islem yapiliyor
        }


    }
}

</script>