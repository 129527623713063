export const func = {
    format_date_db: (date_value) => {
        const date = new Date(date_value);
        const newdate = date.toLocaleDateString("tr-TR") + " " + date.toLocaleTimeString("tr-TR", { hour12: false, hour: "2-digit", minute: "2-digit" })
        return newdate

        const dd = String(date.getDate()).padStart(2,"0");
        const mm = String(date.getMonth()+1).padStart(2,"0");
        const yyyy = date.getFullYear();
        const hour = date.getUTCHours();
        const minute = date.getMinutes()

        return `${dd}.${mm}.${yyyy} ${hour}:${minute}`
    }

}