<template>
<head>
	<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
	<meta name='viewport' content='width=device-width, initial-scale=1'>
	<title>Bayi Sistemi Giriş Paneli</title>
    
    <!-- Favicon -->
    <link rel="icon" type="image/x-icon" href="img/favicon.ico">
      
	<link href='https://maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css' rel='stylesheet'>
	<link href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.3/css/font-awesome.css' rel='stylesheet'>
</head>

<body oncontextmenu="return false" class="snippet-body">
<div class="limiter" id="login">
    <div class="container-loginform" style="background-image:url(images/login-background.jpg)">
        <div class="container">
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-5 col-md-offset-1">
                        <div class="login_topimg"> </div>
                        <div class="wrap-loginform">
                            <div class="col-lg-12">
                                <div class="logo">
                                    <img src="images/logo_login.png">
                                </div>
                            </div>
                            <div class="loginform-form validate-form" method="post"> <span class="loginform-form-subtitle m-b-16"> B2B Sistemimize hoşgeldiniz. Lütfen giriş yapınız. </span>
                                <div class="wrap-inputfrm validate-input m-b-16" data-validate="Kullanıcı adınızı girmediniz !"> <input class="inputfrm" v-model="email_tele" type="text" @keydown.enter="update_state_withhinfo" name="Username" placeholder="Kullanıcı Adı" required> <span class="focus-inputfrm"></span> <span class="symbol-inputfrm"> <span class="glyphicon glyphicon-user"></span> </span> </div>
                                <div class="wrap-inputfrm validate-input m-b-16" data-validate="Şifrenizi girmediniz !"> <input class="inputfrm" v-model="password_v" @keydown.enter="update_state_withhinfo"   type="password" name="pass" placeholder="Şifre" required> <span class="focus-inputfrm"></span> <span class="symbol-inputfrm"> <span class="glyphicon glyphicon-lock"></span> </span> </div>
                                <div v-show="show_captcha" class="flex-sb-m w-full pb-7 align-top">
                                    <div class="contactfrm-form-checkbox pr-2">
                                        <input class="inputfrm-captcha" v-model="captcha_v" type="text" name="captcha" placeholder="Güvenlik Kodu" maxlength="6" required>
                                    </div>
                                    <div>
                                        <div id="app_captcha">
                                            <div class="captcha p-b-2">
                                                <span class="captcha-char" v-for="(char, index) in captchaChars" :key="index"
                                                        :style="{ fontSize: char.fontSize + 'px', transform: 'rotate(' + char.rotation + 'deg)' }">
                                                        {{ char.char }}
                                                </span>
                                            </div>
                                            <button class="loginform-form-refresh-btn" @click="generateCaptcha">Yenile</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-sb-m w-full p-b-30">
                                    <div class="contactfrm-form-checkbox"> <input class="input-checkboxfrm" id="ckb1" type="checkbox" name="remember-me"> <label class="label-checkboxfrm" for="ckb1"> Beni hatırla </label> </div>
                                    <div> <a href="#" class="txt1"> Şifremi Unuttum? </a> </div>
                                </div>
                                <div class="container-loginform-form-btn p-t-25"><button @click="update_state_withhinfo" class="loginform-form-btn" id="login_button" v-show="isDisabled"> Giriş Yap </button><button class="loginform-form-btn" id="login_button-2" v-show="isDisabled2"> Lütfen Bekleyiniz... </button></div>
                            </div> 
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>


<div class="footer">
    <div class="global-container">
        <div class="footer-left">
            <img src="images/ortaksoft.png" alt="OrtakSoft B2B Çözümleri" onclick="window.open('https://www.ortaksoft.com','_blank')">
            <span class="pl-5 mt-2">OrtakSoft B2B - © 2022 Tüm Hakları Saklıdır</span>
        </div>
        <div style="float: right;">

        </div>
    </div>
</div>
</body>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    async created(){

        if ((this.$route.query.code != "") && (this.$route.query.code != null)){
            if (this.$route.query.code.includes('-') == true){
                var code_in = this.$route.query.code.split('-');
                var email_tele = code_in[0];
                var password_v = code_in[1];
                if ((email_tele != "") && (password_v != "")){
                    const user_acc =  await this.$store.dispatch('testing_login_info', { acc_email:email_tele, acc_password:password_v})
                    .then( (res) => {
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                    if( user_acc )
                    {
                        this.$router.push(
                            {name: 'home'}
                        )
                    } else 
                    {
                        this.$toast.error("Kullanıcı Bulunamadı ! Lütfen satış temsilcinize bilgi veriniz.")

                        this.isDisabled = true;
                        this.isDisabled2 = false;
                    }
                }
            }
        }
    },

    data(){
        return {
            email_tele  : '',
            password_v  : '',
            loginfailed : false,
            isDisabled  : true,
            isDisabled2 : false,
            show_captcha: false,
            logintrial  : 0,
            captchaChars: [],
			characters: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
        }
    },
    // created() {
    //     this.$store.commit('toolBar', { pageTitle: 'Bayi Portalı Giriş Paneli' })
    // },
    computed: {
        ...mapGetters(
            { 
              loginsuccess     : 'get_loginsuccess',
              account_info     : 'get_account' ,
              //store_size       : 'get_store_size',
            
            },

        )
    },

    mounted() {
		this.generateCaptcha();
	},

    methods: { /*
        onclick_login_button(){
            //check if input is proper///
            if (this.password_v == ''  || this.email_telephone == '') {
                console.log('bad pass or login ')
            } else {
                axios.post('/giris-yap', {
                    email: this.email_telephone,
                    password : this.password_v
                }).then(function (response) {
                    console.log(response.data);
                    ///// check response 
                    ///// set data to local 
                    //this.update_state_withhinfo()
                }).catch(function (error) {
                        console.log(error);
                });

                //// call store update

                this.$router.push('/home')
            }
        }*/
        generateCaptcha() {
			const chars = this.characters.split('');
			let captchaChars = [];
			for (let i = 0; i < 6; i++) {
				let char = chars[Math.floor(Math.random() * chars.length)];
				let fontSize = Math.floor(Math.random() * 10) + 20; // rastgele yazi buyuklugu 20-30 arasi
				let rotation = Math.floor(Math.random() * 21) - 10; // rastgele goruntulenme acisi -10 ve 10 derece
				captchaChars.push({ char, fontSize, rotation });
			}
			this.captchaChars = captchaChars;
            this.captcha_v = "";
		},

        async update_state_withhinfo(){
            
            if ((this.email_tele == null) || (this.email_tele == "")){
                this.$toast.error('Kullanıcı adınızı yazmadınız !', {
                    position: "bottom",
                    duration : 5000
                })
                return
            }
            
            if ((this.password_v == null) || (this.password_v == "")){
                this.$toast.error('Kullanıcı adınızı yazmadınız !', {
                    position: "bottom",
                    duration : 5000
                })
                return
            }

            if (this.show_captcha == true){
                if ((this.captcha_v == null) || (this.captcha_v == "")){
                    this.$toast.error('Güvenlik kodunu yazmadınız !', {
                        position: "bottom",
                        duration : 5000
                    })
                    return
                } else {
                    const captchaStr = this.captchaChars.map((i) => i.char).join('')
                    if (captchaStr != this.captcha_v){
                        this.$toast.error('Güvenlik kodunu hatalı girdiniz !', {
                            position: "bottom",
                            duration : 5000
                        })
                        return
                    }
                }
            }

            this.isDisabled = false;
            this.isDisabled2 = true;

            // B 26.11.2022 - Gunluk kur kontrolu yapiliyor
            const date  = new Date();
            const yyyy  = date.getFullYear();
            const mm    = String(date.getMonth() + 1).padStart(2,'0');
            const dd    = String(date.getDate()).padStart(2,'0');

            const exchange_date = `${yyyy}${mm}${dd}`

            const exchange_control = await this.$store.dispatch('exchange_control', { exchange_date: exchange_date })
            // E 26.11.2022 - Gunluk kur kontrolu yapiliyor

            const user_acc =  await this.$store.dispatch('testing_login_info', { acc_email:this.email_tele, acc_password:this.password_v})
            .then( (res) => {
                //console.log("store_size:" + this.store_size)
                return res
            })
            .catch(
                (err)=>{
                    console.log(err)
                }
            )
            if( user_acc )
            {
                this.$router.push(
                    {name: 'home'}
                )
            } else 
            {
                this.$toast.error("Kullanıcı Bulunamadı ! Lütfen satış temsilcinize bilgi veriniz.")

                this.logintrial += 1;
                this.show_captcha = (this.logintrial == 3);

                this.isDisabled = true;
                this.isDisabled2 = false;
            }
        }
    }

}
</script>

<style scoped>
@import '../../public/css/loginform.css'
</style>