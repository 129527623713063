<template>

    <div class="flex flex-col items-center w-full pl-0 md:pl-3">

      <div class="items-center align-middle font-bold text-xl md:text-2xl w-full mb-3 md:mb-5" v-bind:style='{ color : this.settings.theme_color_2.banner_text_color }'>
        {{ this.account_info.name }}
      </div>
      <div class="items-center justify-center h-25 w-full">
        <form 
        autocomplete="off"
        class="flex flex-row flex-nowrap w-full"
        @submit.prevent="search_entered" 
        @keydown.enter.prevent="search_entered"
        >
        <!-- SEARCH INPUT TEXT        -->
            <input
              autocomplete="off"
              v-model="searchstring"
              class="border-l-2 border-t-2 border-b-2 border-r-0 border-solid border-gray-400 text-black bg-white h-20 pl-5 md:pl-12 pr-16 text-3xl w-10/12 rounded"
              type="text"
              placeholder="Ürün, Marka veya Kategori Adı Yazınız..."
              id="searchbar"
              ref="searchbar"
              @click="searchModal(true)"
              @focus="searchModal(true)"
              @keypress="searchModal(true)"
              @keydown="searchModal(true)"
            />
            <div v-show="searchstring_clear" class="border-l-0 border-t-2 border-b-2 border-r-0 border-solid border-gray-400 h-20 cursor-pointer ml-[-40px] pt-2 bg-white w-16 align-middle text-left" v-tooltip.bottom="'Temizle'" @click="this.searchstring = ''; this.searchstring_clear = false;" >
              <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-16 cursor-pointer text-gray-700">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </div>
            <button
              type="submit"
              class="rounded-r-lg cursor-pointer bg-black font-bold text-white w-2/12 h-20 text-4xl"
              v-show="!lock_search_but" v-bind:style='{ backgroundColor : settings.theme_color_2.count }'
            > Ara
            </button>
            <button
              type="submit"
              class="rounded-r-lg cursor-pointer bg-black font-bold text-white w-2/12 h-20 text-3xl"
              v-show="lock_search_but" v-bind:style='{ backgroundColor : settings.theme_color_2.count }'
              disabled="true"
            >Aranıyor...
            </button>
        </form>
      </div>

  <div v-show="showmodal" @click="searchModal(false)" ref="search_table" id="search_table" class="fixed top-[140px] md:top-48 left-0 grid w-screen h-screen backdrop-blur-sm bg-gray-500/50 z-[1050]">



  </div>

        <div>

          
        </div>
      </div>

      <!--  @keypress.enter="search_entered" -->
      <div v-show="showmodal" :style="style" class="fixed flex flex-col border border-gray-300 rounded-lg p-7 min-w-[50%] bg-white shadow-xl w-[95%] md:w-[55%] z-[1060]">
                <div id="modalbody" class="flex flex-col md:flex-row top-0">
                  <div class="flex flex-col top-0 md:grid md:grid-cols-3 gap-4 justify-between w-full">
                    <div class="md:col-span-3 pb-1 md:pb-0">
                      <div class="relative w-full h-10 flex flex-nowrap justify-between">
                        <div class="font-semibold text-3xl">
                          Arama Seçenekleri:
                        </div>
                        <div class="float-right">
                          <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer transform transition-transform duration-500 ease-in-out" width="24" height="24" viewBox="0 0 20 20" fill="gray" stroke-width="2" @click="searchModal(false)">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div v-if="(get_searchhistory || []).length > 0" class="md:col-span-3 pb-1 md:pb-2 flex flex-col md:flex-row md:flex-nowrap w-full border-b border-solid border-gray-300">
                      <div class="text-xl font-semibold md:mr-2">
                        Geçmiş Aramalarınız:
                      </div>
                      <div v-for="keywrd of get_searchhistory" :key="keywrd" @click="search_keyword(keywrd)" class="text-xl md:mr-3 cursor-pointer text-blue-800 hover:underline">
                        {{ keywrd }}
                      </div>
                    </div>
                    <div class="flex flex-row flex-nowrap w-full md:col-span-3">
                      <div class="flex flex-col w-1/3">
                        <div class="relative w-full h-14 flex flex-nowrap mb-2 md:mb-3">
                            <select 
                            id = "cat1_product"
                            @change="search_filter($event)"
                            name="s_productgroup_1" 
                            class="text-xl w-full p-2 bg-gray-100 border border-gray-300 rounded-lg">
                              <option value="">Ürün Grubu</option>
                              <option v-for="opt in this.search_cat1" :value="opt.nameSEO" :key="opt.code">{{ opt.name }}</option>
                            </select>
                        </div>
                        <div class="relative w-full h-14 flex flex-nowrap mb-2 md:mb-3">
                            <select 
                            id = "cat2_product"
                            ref = "cat2_product"
                            @change="search_filter($event)"
                            name="s_productgroup_2" 
                            class="text-xl w-full p-2 bg-gray-100 border border-gray-300 rounded-lg" disabled>
                              <option value="">Alt Ürün Grubu</option>
                              <option v-for="opt in this.search_cat2" :value="opt.nameSEO" :key="opt.code">{{ opt.name }}</option>
                            </select>
                        </div>
                        <div class="relative w-full h-14 flex flex-nowrap mb-2 md:mb-3">
                            <select 
                            id = "cat3_product"
                            ref = "cat3_product"
                            @change="search_filter($event)"
                            name="s_productgroup_3" 
                            class="text-xl w-full p-2 bg-gray-100 border border-gray-300 rounded-lg" disabled>
                              <option value="">Alt Ürün Grubu</option>
                              <option v-for="opt in this.search_cat3" :value="opt.nameSEO" :key="opt.code">{{ opt.name }}</option>
                            </select>
                        </div>
                      </div>
                      <div class="flex flex-col w-2/3">
                        <div class="grid grid-cols-2 w-full col-span-3">
                          <div class="relative w-full h-14 flex flex-nowrap mb-2 md:mb-3 pl-0 md:pl-4">
                            <select 
                            id = "marka"
                            @change="search_filter($event)"
                            name="s_producer" 
                            class="text-xl w-full p-2 bg-gray-100 border border-gray-300 rounded-lg">
                              <option value="">Marka (Üretici)</option>
                              <option v-for="opt in ureticiler" :value="opt" :key="opt"> {{ opt }}</option>
                            </select>
                          </div>
                          <div class="relative w-full h-14 flex flex-nowrap mb-2 md:mb-3 pl-0 md:pl-4">
                              <select 
                              id = "arac"
                              @change="search_filter($event)"
                              name="s_productbrand" 
                              class="text-xl w-full p-2 bg-gray-100 border border-gray-300 rounded-lg">
                                <option value="">Kullanılan Araç</option>
                              </select>
                          </div>
                          <div class="relative w-full h-12 pl-0 md:pl-4 flex flex-nowrap">
                              <div class="mr-2">
                                <input type="checkbox" name="s_newproduct" id="s_newproduct" v-model="new_product" class="w-8 h-8 cursor-pointer">
                              </div>
                              <div class="pl-2">
                                <label for="s_newproduct" class="text-xl cursor-pointer">Yeni Ürünler</label>
                              </div>
                          </div>
                          <div class="relative w-full h-12 pl-0 md:pl-4 flex flex-nowrap">
                              <div class="mr-2 cursor-pointer">
                                <input type="checkbox" name="s_campaign" id="s_campaign" v-model="camp_product" class="w-8 h-8 cursor-pointer">
                              </div>
                              <div class="pl-2">
                                <label for="s_campaign" class="text-xl cursor-pointer">Kampanyalar</label>
                              </div>
                          </div>
                          <div class="relative w-full h-12 pl-0 md:pl-4 flex flex-nowrap">
                              <div class="mr-2 cursor-pointer">
                                <input type="checkbox" name="s_myfavorite" id="s_myfavorite" v-model="fave_product" class="w-8 h-8 cursor-pointer">
                              </div>
                              <div class="pl-2">
                                <label for="s_myfavorite" class="text-xl cursor-pointer">Favori Ürünlerim</label>
                              </div>
                          </div>
                          <div class="relative w-full h-12 pl-0 md:pl-4 flex flex-nowrap">
                              <div class="mr-2 cursor-pointer">
                                <input type="checkbox" name="s_ontheway" id="s_ontheway" v-model="otw_product" class="w-8 h-8 cursor-pointer">
                              </div>
                              <div class="pl-2">
                                <label for="s_ontheway" class="text-xl cursor-pointer">Yoldaki Ürünler</label>
                              </div>
                          </div>
                          <div class="relative w-full h-12 pl-0 md:pl-4 flex flex-nowrap">
                              <div class="mr-2 cursor-pointer">
                                <input type="checkbox" name="s_instock" id="s_instock" v-model="stock_product" class="w-8 h-8 cursor-pointer">
                              </div>
                              <div class="pl-2">
                                <label for="s_instock" class="text-xl cursor-pointer">Stokta Olanlar</label>
                              </div>
                          </div>
                          <div class="relative w-full h-12 pl-0 md:pl-4 flex flex-nowrap">
                              <div class="mr-2 cursor-pointer">
                                <input type="checkbox" name="s_withimage" id="s_withimage" v-model="img_product" class="w-8 h-8 cursor-pointer">
                              </div>
                              <div class="pl-2">
                                <label for="s_withimage" class="text-xl cursor-pointer">Resimli Ürünler</label>
                              </div>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "Search",
  data() {
    return {
      searchstring: "",
      selectedDisplay: null,
      showmodal: false,
      new_product   : false,
      camp_product  : false,
      fave_product  : false,
      otw_product   : false,
      stock_product : false,
      img_product   : false,
      search_filter : {},
      lock_search_but: false,
      div_top       : 0,
      div_left      : 0,
      searchstring_clear  : false,
      search_categories   : [],
      cat1_product  : "",
      cat2_product  : "",
      cat3_product  : "",
      search_cat1   : [],
      search_cat2   : [],
      search_cat3   : [],
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  async mounted() {
    
      const get_categories = await this.$store.dispatch("get_categories")

      this.search_categories = (get_categories != undefined) ? get_categories : []
      this.search_cat1 = (this.search_categories.length > 0) ? this.search_categories.map((i) => {
                                                                                      return {
                                                                                        "code"    : i.code,
                                                                                        "name"    : i.name,
                                                                                        "nameSEO" : i.nameSEO,
                                                                                      }
                                                                                    }) : []
  },

  computed: {

    ...mapGetters(
      {
        settings            : 'get_settings',
        account_info        : 'get_account',
        get_salesperson     : 'get_salesperson',
        get_user_searchpool : 'get_user_searchpool',
        search_string       : 'search_string',
      }
    ),

    //B 16.05.2023 - kullanici urun arama kayit islemleri
    get_searchhistory(){
      var search_keywords = []
      if (this.get_user_searchpool != undefined){
        // if((this.search_string != '')&&(this.get_user_searchpool[0] != undefined)&&(this.$route.name = "search"))this.searchstring = this.get_user_searchpool[0].searchstring || "" //15.06.2023 - son arama kelimesi getirildi
        for (let i of this.get_user_searchpool){
          if ((!search_keywords.includes(i.searchstring)) && (i.searchstring != "") && (i.searchstring != null)){
            search_keywords.push(i.searchstring)
            if (search_keywords.length == 5){ break; }
          }
        }
      }
      return search_keywords
    },
    //E 16.05.2023 - kullanici urun arama kayit islemleri

    style(){
      return `top: ${this.div_top}px; left: ${this.div_left}px;`
    },

    async ureticiler() {
      // const distpatch_payload = {
      //   domain: this.account_info.domain,
      // }
      // const ret = await this.$store.dispatch('search_filter_lists', distpatch_payload)

      // console.log(ret)
      return this.settings.distinct_brandseo
    },


    //// IS GORUR SU ANLIK 
    displays_emr: function () {
      var menucategories = [];

      try {

        /// FILTER HATALILILARI AYILKAMAK ICIN
        const allcats =
          this.$store.getters.get_account.site_settings.categories
          .filter(
            (i) => (i.nameSEO   != '')    
          );

        const maincats    = allcats.filter((i) => (i.parentname == "" && i.nameSEO  != ''));
        const mainsubcats = allcats.filter((i) => (i.parentname != "" && i.nameSEO  != ''));

        const ret = []
        const get_label_menus = maincats
        .map(
          (i) => {            
            ret.push(i)
            mainsubcats.filter(
                (j) => {
                  return j.parentname == i.nameSEO
                }
              )
              .map (
                (k) => {
                  ret.push(k)
                  return k
                }
              )
            }
        )

        return ret
        
      } catch {
        console.log("err0r0---asd ");
      }
    },
    displays_emr2: function () {
      try {

        /// FILTER HATALILILARI AYILKAMAK ICIN
        const allcats =
          this.$store.getters.get_account.site_settings.categories
          .filter(
            (i) => (i.nameSEO   != '')    
          );

        const maincats    = allcats.filter((i) => (i.parentname == "" && i.nameSEO  != ''));
        const mainsubcats = allcats.filter((i) => (i.parentname != "" && i.nameSEO  != ''));

        const get_label_menus = maincats
        .map(
          (i) => {   

            return {
            label: i.name,
            items: mainsubcats.filter(
                (j) => {
                  return j.parentname == i.nameSEO
                }
              )
              .map (
                (k) => {
                  return  {label: k.name}
                }
              )
            }

          }
        )
        return get_label_menus
      } catch {

      }
    }

  },
  methods: {

    myEventHandler(){
      if (this.$refs.searchbar.getBoundingClientRect() != null){
        const searchbar_left = this.$refs.searchbar.getBoundingClientRect().left;
        const searchbar_top = this.$refs.searchbar.getBoundingClientRect().top;
        const searchbar_height = this.$refs.searchbar.getBoundingClientRect().height;

        this.div_top =  parseInt(searchbar_top) + parseInt(searchbar_height) + 10;
        this.div_left = parseInt(searchbar_left);
      }
    },

    searchModal(e) {
      this.showmodal = e;
      
      this.searchstring_clear = this.searchstring.length > 0 //29.03.2023 - metni temizlemek icin eklendi


      // var elem_searchbar = document.getElementById('searchbar')
      // var elem_search_table = document.getElementById('search_table')

      // var p = document.getElementById("searchbar");
      // var pstyle = p.currentStyle || window.getComputedStyle(p);

      // pstyle.top = '25px';

      // console.log("Current marginTop: " + pstyle.style.top);

      // let searchbar = document.querySelector('#searchbar');
      // let search_table = document.querySelector('#search_table');

      // const elem_searchbar = document.getElementById('searchbar');
      // const style= getComputedStyle(elem_searchbar);

      // console.log(itemTopmargin)

      const searchbar_left = this.$refs.searchbar.getBoundingClientRect().left;
      const searchbar_top = this.$refs.searchbar.getBoundingClientRect().top;
      const searchbar_height = this.$refs.searchbar.getBoundingClientRect().height;

      this.div_top =  parseInt(searchbar_top) + parseInt(searchbar_height) + 10;
      this.div_left = parseInt(searchbar_left);

      // console.log("searchbar left: " + searchbar_left + " :: searchbar top:" + searchbar_top)

      // let ptX = searchbar.marginTop;
      // let ptY = searchbar.marginLeft;

      // console.log("ptX: " + ptX + " :: ptY:" + ptY)

      // pricetable.position = "absolute";
      // pricetable.style.left = ptX + farkX + "px";
      // pricetable.style.top = ptY + farkY + "px";

      // elem_search_table.style.top = elem_searchbar.style.top + elem_searchbar.style.height + 10 + "px";

      // $(this.$refs.search_table).css('top', '10px')

      return
    },

    //B 16.05.2023 - kullanici urun arama kayit islemleri
    async search_keyword(value_in){
      if ((value_in != undefined) && (value_in != '') && (value_in != null)){
        this.searchstring = value_in
      }
      this.search_entered()
    },
    //E 16.05.2023 - kullanici urun arama kayit islemleri

    async search_entered() {

      if(this.lock_search_but)
        return;
      this.lock_search_but = true
      this.showmodal = false

      var search_filter_ = {}

      if(this.search_filter != {}){ 

        const all_keys = Object.keys(this.search_filter)
        const place = all_keys.map(
          i => {
            if(this.search_filter[i] != '')
            {
              search_filter_[i] = this.search_filter[i]
            }
          }
        )
        
      }

      await this.get_account_info()

      if(this.new_product)    search_filter_['new_product']   = this.new_product;
      if(this.camp_product)   search_filter_['camp_product']  = this.camp_product;
      if(this.fave_product)   search_filter_['fave_product']  = this.fave_product;
      if(this.otw_product)    search_filter_['otw_product']   = this.otw_product;
      if(this.cat1_product)   search_filter_['cat1_product']  = this.cat1_product;
      if(this.cat2_product)   search_filter_['cat2_product']  = this.cat2_product;
      if(this.cat3_product)   search_filter_['cat3_product']  = this.cat3_product;
      if(this.stock_product)  search_filter_['stock_product'] = this.stock_product;
      if(this.img_product)    search_filter_['img_product']   = this.img_product;
    
      if (this.searchstring === "" && search_filter_ == {} ) {
        return;
      }

      const dispatchpayload = {
                    page          : 'search',
                    isonlyload    : false,
                    search_string : this.searchstring,
                    filtre        : '',
                    sort          : '',
                    search_filter  : search_filter_

      }

      const results = await this.$store
        .dispatch("loadmoreresults", dispatchpayload)
        .then((res) => {
          this.$router.push("/search");
          return res;
        });

      //B 16.05.2023 - kullanici urun arama kayit islemleri
      const user_results = await this.$store.dispatch("user_product_searchpool", {
        user_type     : 'customer', // customer or sales
      })
      //E 16.05.2023 - kullanici urun arama kayit islemleri

      window.scrollTo(0,0);

      this.lock_search_but = results
    },
  
        async get_account_info(){

            if (this.account_info.lastlogindate != undefined){ // 10.03.2023 - Gunluk giris tarih kontrolu
              const controlToday    = new Date();
              const controlDate     = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
              const UserDate        = new Date(this.account_info.lastlogindate);
              const controlUserDate = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
              if (Number(controlDate) != Number(controlUserDate)){
                this.$router.push(
                    {name: 'login'}
                )
              } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                  const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }
              }

            } else { // 09.03.2023 - Sistem guncellenme kontrolu 
              const system_status_info =  await this.$store.dispatch('system_control')
              .then( (res) => {
                  return res
              })
              .catch(
                  (err)=>{
                      console.log(err)
                  }
              )

              if (!system_status_info){
                  this.$router.push(
                      {name: 'login'}
                  )
                  // console.log(system_status_info)
                  // console.log("this.account_info.system_status:" + this.account_info.system_status)

              } else { // 17.11.2022 guncel tags bilgileri aliniyor
                const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                  .then( (res) => {
                      //console.log("Kullanıcı bulundu !")
                      return res
                  })
                  .catch(
                      (err)=>{
                          console.log(err)
                      }
                  )
              }
            }




        },

    search_filter(event)
    { 
      this.search_filter[event.target.id] = event.target.value

      //B 20.06.2023 - Kategori filtreleri arama seceneklerine dahil edildi
      if (event.target.id == "cat1_product"){
        if (event.target.value != ""){
          this.$refs.cat2_product.disabled = false
          const cat1_arr = this.search_categories.filter((i) => (i.nameSEO == event.target.value))
          this.search_cat2 = (cat1_arr[0] != undefined) ? cat1_arr[0].sub_categories : []
        } else {
          this.search_cat2 = []
          
          this.$refs.cat2_product.disabled = true
        }
        this.$refs.cat2_product.value    = ""
        this.search_cat3                 = []
        this.$refs.cat3_product.value    = ""
        this.$refs.cat3_product.disabled = true
        delete this.search_filter['cat2_product'];
        delete this.search_filter['cat3_product'];

      } else if (event.target.id == "cat2_product"){
        if (event.target.value != ""){
          this.$refs.cat3_product.disabled = false
          const cat2_arr = this.search_cat2.filter((i) => (i.nameSEO == event.target.value))
          this.search_cat3 = (cat2_arr[0] != undefined) ? cat2_arr[0].sub_categories : []
        } else {
          this.search_cat3 = []
          this.$refs.cat3_product.disabled = true
        }
        this.$refs.cat3_product.value    = ""
        delete this.search_filter['cat3_product'];
      }
      //E 20.06.2023 - Kategori filtreleri arama seceneklerine dahil edildi

    },

  },

};
</script>