<template>
    
    <div class="flex items-center justify-between md:justify-end w-full">

        <div v-show="this.settings.theme_color_3.banner_debt_information" class="border border-solid border-gray-500 text-black hover:text-white rounded-xl p-1.5 mt-5 md:mt-0 mb-1 ml-4 md:ml-2 mr-2 md:mr-3 relative inline-block items-center cursor-pointer hover:bg-gray-300"
            @click="router_push_to( {
                name:'payment',
                params: { activetableroute: 'orders'}
            })
        ">
            <div class="flex flex-col flex-nowrap md:mr-1 min-w-[160px]">
                <div class="text-xl font-semibold text-left pl-1 text-red-600">
                    Vadesi Geçen Borç:
                </div>
                <div class="text-2xl pr-1.5 pt-1.5 font-semibold text-right text-red-600">
                    {{ (this.outofdate_total_info || 0).toLocaleString("tr-TR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} TL
                </div>
            </div>
        </div>
        
        <div class="border border-solid border-red-500 text-black hover:text-white rounded-xl p-1.5 mt-5 md:mt-0 mb-1 ml-4 md:ml-2 mr-3 md:mr-4 relative inline-block items-center cursor-pointer hover:bg-red-500"
            @click="router_push_to( {
                name:'dash',
                params: { activetableroute: 'favorites'}
            })
        ">
            <div class="flex flex-row flex-nowrap p-1 md:mr-1 min-w-[120px]">
                <div>
                    <!-- HEART  -->
                    <svg class="h-12 w-12 md:h-12 md:w-12 stroke-white fill-red-500 hover:fill-red-700" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    <!-- END HEART END  -->
                    <span class="absolute bottom-12 right-[-8px] flex justify-center items-center align-top w-8 h-8 text-2xl font-bold text-white rounded-full" v-bind:style='{ backgroundColor : this.settings.theme_color_3.count }'> {{faves_count}} </span>
                </div>
                <div class="text-xl pl-1.5 pt-2.5 pb-1 font-semibold text-center">
                    Favorilerim
                </div>
            </div>
        </div>

        <div class="border border-solid border-gray-500 text-black hover:text-white rounded-xl p-1.5 mt-5 md:mt-0 mb-1 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer hover:bg-gray-500"
            @click="router_push_to( {
                name:'cart',
                params: { activetableroute : ''}
            })
        ">
            <div class="flex flex-row flex-nowrap p-1 md:mr-1 min-w-[120px]">
                <div>
                    <!-- CART  -->
                    <svg class="h-12 w-12 md:h-12 md:w-12 stroke-white fill-gray-500 hover:fill-gray-700" viewBox="0 0 20 20" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                    </svg>
                    <!-- END CART END  -->
                    <span class="absolute bottom-12 right-[-8px] flex justify-center items-center align-top w-8 h-8 text-2xl font-bold text-white rounded-full bg-black"> {{cart_count}} </span>
                </div>
                <div class="text-xl pl-1.5 pt-2.5 pb-1 font-semibold text-center">
                    Sepetim
                </div>
            </div>
        </div>

    </div>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    methods: {
        ...mapActions(
            ['req_detailed_faves']
            
        ),

        router_push_to( locationNparams ){
            this.$router.push({
                name:locationNparams.name,
                params: locationNparams.params
            })

            // B 17.11.2022 guncel tags bilgileri aliniyor
            if (locationNparams.name == 'cart' ){
                this.get_account_info()
            }
            // E 17.11.2022 guncel tags bilgileri aliniyor

          // B 30.11.2022  -   Favorilerim sayfa duzenlemesi
          if (locationNparams.params.activetableroute == 'favorites'){
                    this.req_detailed_faves()
            }
            // E 30.11.2022  -   Favorilerim sayfa duzenlemesi
        },

        async get_account_info(){


            if (this.account_info.lastlogindate != undefined){ // 10.03.2023 - Gunluk giris tarih kontrolu
              const controlToday    = new Date();
              const controlDate     = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
              const UserDate        = new Date(this.account_info.lastlogindate);
              const controlUserDate = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
              if (Number(controlDate) != Number(controlUserDate)){
                this.$router.push(
                    {name: 'login'}
                )
              } else {// 09.03.2023 - Sistem guncellenme kontrolu 
                    const system_status_info =  await this.$store.dispatch('system_control')
                    .then( (res) => {
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )

                    if (!system_status_info){
                        this.$router.push(
                            {name: 'login'}
                        )
                        // console.log(system_status_info)
                        // console.log("this.account_info.system_status:" + this.account_info.system_status)

                    } else { // 17.11.2022 guncel tags bilgileri aliniyor
                        const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                        .then( (res) => {
                            //console.log("Kullanıcı bulundu !")
                            return res
                        })
                        .catch(
                            (err)=>{
                                console.log(err)
                            }
                        )
                    }
              }
            } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }
            }
        },
    },

    computed: {
        ...mapGetters({
            account_info        : 'get_account',
            cart_info           : 'get_account_cart_items',
            settings            : 'get_settings',
            outofdate_total_info: 'get_outofdate_total_info' //23.06.2023 - Nakde dusen borc toplami
        }),

        cart_count () {
            const retthis = this.cart_info.filter(
                i => {return i.itemID}
            ).length
            return retthis
        },
        faves_count(){
            return (this.account_info.favorite_products == undefined) ? 0 : this.account_info.favorite_products.length //03.12.2022 - favorite_products degeri yok ise islem yapiliyor
        },

    }
}

</script>