import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import global_mixins from './global_mixins'


import './assets/tailwind.css';



///////////////// PRIME VUE     \ 

//import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
// import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import TabView from 'primevue/tabview';
import Column from 'primevue/column';
// import ColumnGroup from 'primevue/columngroup';
//import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
//import Card from 'primevue/card';
import Rating from 'primevue/rating';
// import Textarea from 'primevue/textarea';
// import InputNumber from 'primevue/inputnumber';
import Tag from 'primevue/tag'
import Tooltip from 'primevue/tooltip';
// import Dropdown from 'primevue/dropdown';
// import MegaMenu from 'primevue/megamenu';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
// import RadioButton from 'primevue/radiobutton';
import Carousel from 'primevue/carousel';
import Toaster from "@meforma/vue-toaster";
// import ToastService from 'primevue/toastservice'

import Header from "@/components/partials/header_v.vue"
import Footer from "@/components/partials/footer_v.vue"


import './input.css'

import {func} from './func'
// const app = 

createApp(App)

//app.provide(func, app.config.globalProperties.func)
//app.config.globalProperties.$func = func()


////////////////////////// VUE PRIME 

.component('Header',Header)
.component('Footer',Footer)

// .component('Dialog',Dialog)
.component('InputText',InputText)
.component('DataTable',DataTable)
.component('TabView',TabView)
.component('Column',Column)
// .component('ColumnGroup',ColumnGroup)
//.component('Calendar',Calendar)
.component('Button',Button)
.component('Menu',Menu)
//.component('Card',Card)
.component('Rating',Rating)
// .component('Textarea',Textarea)
// .component('InputNumber',InputNumber)
// .component('Dropdown',Dropdown)
.component('Tag', Tag)
.component('Carousel', Carousel)
// .component('MegaMenu', MegaMenu)
.component('Galleria', Galleria)
.component('Image', Image)
// .component('RadioButton', RadioButton)

.directive('tooltip', Tooltip)


.use(PrimeVue)
//.use(vfmPlugin)
.use(Toaster)
// .use(ToastService)
.use(router)
.use(store)
.use(func)
// .use(VueExcelXlsx)


.mixin(global_mixins)





.mount('#app')