import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import store from '../store/index'



const routes = [

  {
    path: '/',
    name:'login',
    component: LoginView
  },
  {
    path: '/satis_giris',
    name:'satis_giris',
    component: () => import('../views/SatisGirisView.vue')
  },
  {
    path: '/kolay-ode/:vendorId',
    name:'kolay_ode',
    component: () => import('../views/EasyPayView.vue') // 21.11.2022 - Kolay-ode 3D gelistirmesi
  },
  {
    path: '/emutabakat/:recId',
    name:'emutabakat',
    component: () => import('../views/ReconciliationView.vue') // 28.12.2022 - e-Mutabakat gelistirmesi
  },
  {
    path: '/satis_home',
    name:'satis_home',
    component: () => import('../views/SatisDashView.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_satisloginsuccess // 25.11.2022  -   Satis yoneticisi giris tarih kontrolu
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/satis_giris'};
      }
    }
  },
  {
    path: '/dashboard',
    name: 'home',
    component: () => import( '../views/HomeView.vue'),
    props: true,
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    }
  },
  {
    path: '/kurumsal/:page_link',
    name: 'kurumsal',
    component: () => import( '../views/PageView.vue'),
    props: true,
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  }, 
  {
    path: '/about',
    name: 'about',
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/blog',
    name:'blog',
    component: () => import(/* webpackChunkName: "about" */ '../views/BlogView.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path:'/dash/:activetableroute',
    name:'dash',
    component: () => import( '../views/DashboardView.vue'),
    props: true,            /////////-------------- PARAMSLER PROP OLUYOR BU ROUTETA 
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  // {
  //   path:'/orders',
  //   name:'orders',
  //   component: () => import( '../views/OrdersView.vue')
  // },
  {
    path:'/cart',
    name:'cart',
    component: () => import( '../views/CartView.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path:'/returncart',
    name:'returncart',
    component: () => import( '../views/CartReturnView.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/MakeAPayment.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path: '/paymenttest',
    name: 'paymenttest',
    component: () => import('../views/TestMakeAPayment.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: LoginView,
    // component: () => import('../views/AdminView.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/search',
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_loginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  // {
  //   path: '/404',
  //   name:'404',
  //   component: () => import('../views/404.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
