<template>
    <footer class="pt-16 bg-gray-100 w-full">
    <div class="max-w-[1650px] m-0 mr-auto ml-auto pl-2 pr-2 md:pl-14 md:pr-14">

        <div class="flex flex-col w-full">

            <div class="flex flex-wrap grid-cols-4 w-full pb-9 justify-between">

                <div class="relative p-6 mb-3">
                    <h4 class="mb-8 text-black font-semibold text-3xl">İletişim</h4>
                    <div class="text-2xl">
                        <p class="pb-4 text-orange-600 text-4xl font-semibold">Çağrı Merkezi</p>
                        <h3 class="text-[#0e288e] text-5xl font-bold pb-4">{{ this.settings.call_center_phone }}</h3>
                        <p>{{ this.settings.address1 }}<br>{{ this.settings.address2 }}<br><a :href="'mailto:' + this.settings.sales_email ">{{ this.settings.sales_email }}</a></p>
                        <h4 class="mb-6 pt-16 text-black font-semibold text-3xl">Bizi Takip Edin</h4>
                        <div class="flex flex-nowrap">
                            <div v-if="(this.settings.social_url.facebook != undefined) && (this.settings.social_url.facebook != '')" class="mr-5 bg-gray-400 rounded-full w-16 h-16 items-center text-center pl-3 pt-3"><a class="facebook" :href="this.settings.social_url.facebook" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" class="w-9 h-9" viewBox="0 0 23 45" fill="#fff"> <path d="M7 11v6H0v8h7v20h8V25h7v-8h-7v-5c0-2.3.8-4 4-4h4V0h-6C11.2 0 7 4.2 7 11z"></path> </svg></a></div>
                            <div v-if="(this.settings.social_url.twitter != undefined) && (this.settings.social_url.twitter != '')" class="mr-5 bg-gray-400 rounded-full w-16 h-16 items-center text-center pl-3 pt-3"><a class="twitter" :href="this.settings.social_url.twitter" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" class="w-9 h-9" viewBox="0 0 44.7 35.1" fill="#fff"> <path d="M44.4 4.1c-1.3.6-2.7 1-4.2 1.2 1.5-1.1 2.7-2.7 3.3-4.4 0-.1 0-.2-.1-.2-.1-.1-.2-.1-.3 0-1.7 1-3.6 1.7-5.6 2.1C35.8 1 33.4 0 30.9 0c-5.1 0-9.3 4-9.3 9 0 .6.1 1.2.2 1.8C14.6 10.3 8 7 3.5 1.7c0-.1-.1-.1-.2-.1s-.1 0-.2.1c-.8 1.4-1.2 2.9-1.2 4.5 0 2.8 1.3 5.3 3.5 7-1.1-.1-2.2-.5-3.2-1H2c-.1 0-.1.1-.1.2v.1c0 4 2.7 7.4 6.5 8.6-1 .2-2.1.1-3.1 0-.1 0-.2 0-.2.1-.1 0-.1.1-.1.1 1.2 3.5 4.4 5.9 8.1 6.2-3.1 2.2-6.8 3.4-10.7 3.4-.7 0-1.4 0-2.1-.1-.1 0-.2 0-.2.1s0 .2.1.2c4.2 2.6 9.1 4 14 4 16.3 0 26.1-12.8 26.1-25.1V9c1.8-1.2 3.3-2.8 4.5-4.5.1-.1 0-.2 0-.3-.2-.1-.3-.1-.4-.1z"></path> </svg></a></div>
                            <div v-if="(this.settings.social_url.instagram != undefined) && (this.settings.social_url.instagram != '')" class="mr-5 bg-gray-400 rounded-full w-16 h-16 items-center text-center pl-3 pt-3"><a class="instagram" :href="this.settings.social_url.instagram" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" class="w-9 h-9" viewBox="0 0 51 51" fill="#fff"> <path d="M45 0H7C5.2 0 3.7.8 2.4 2.1 1.1 3.4 0 5.2 0 7v38c0 1.8.7 3.7 2 5s3.1 1 5 1h38c1.8 0 3.4-.4 4.7-1.6C51 48.1 51 46.8 51 45V7c0-1.8 0-3.6-1.3-4.9C48.4.8 46.8 0 45 0zM34.8 8.6c0-1.7 1.3-3 3-3H42c1.7 0 3 1.3 3 3v4.6c0 1.7-1.3 3-3 3h-4.2c-1.7 0-3-1.3-3-3V8.6zm1.1 16.2c0 5.7-4.6 10.4-10.4 10.4-5.7 0-10.4-4.6-10.4-10.4 0-.6.1-1.2.2-1.8.9-4.9 5.1-8.5 10.2-8.5s9.3 3.7 10.2 8.5c.1.6.2 1.2.2 1.8zM6.1 45V23h3.2c-.1.6-.1 1.2-.1 1.8 0 9 7.3 16.4 16.4 16.4 9 0 16.4-7.3 16.4-16.4 0-.6 0-1.2-.1-1.8H45v22H6.1z"></path> </svg></a></div>
                            <div v-if="(this.settings.social_url.linkedin != undefined) && (this.settings.social_url.linkedin != '')" class="mr-5 bg-gray-400 rounded-full w-16 h-16 items-center text-center pl-3 pt-3"><a class="linkedin" :href="this.settings.social_url.linkedin" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" class="w-9 h-9" viewBox="0 0 45.1 44.8" fill="#fff"> <path d="M.1 13.8h10v31H.1zM5 9.7c3.2 0 5.2-2.2 5.2-4.8-.1-2.8-2-4.9-5.1-4.9C2 0 0 2.1 0 4.8c0 2.7 1.9 4.9 5 4.9zm28.7 3.1c-5.2 0-8.2 3-9.5 5h-.1v-4h-9c-.1 2.8 0 6.1 0 10v21h10v-18c0-.9 0-1.9.3-2.5.7-1.8 2.3-3.6 4.9-3.6 3.5 0 4.8 2.7 4.8 6.8v17.4h10v-19c0-9.3-5.1-13.1-11.4-13.1z"></path> </svg></a></div>
                            <div v-if="(this.settings.social_url.youtube != undefined) && (this.settings.social_url.youtube != '')" class="mr-5 bg-gray-400 rounded-full w-16 h-16 items-center text-center pl-3 pt-3"><a class="youtube" :href="this.settings.social_url.youtube" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" class="w-9 h-9" viewBox="0 0 42 47.9" fill="#fff"> <path d="M35 21H7c-3.7 0-7 3.6-7 7v14c0 3.4 3.4 5.9 7.1 5.9H35c3.7 0 7-2.5 7-5.9l-.2-14.4c0-3.4-3.1-6.6-6.8-6.6zM6 43l.1-14.7H3.2v-2.2h9.2v2.2H9V43H6zm13.3.3H17v-1.6c-.6 1.3-3.1 2.3-4.4 1.4-.7-.5-.7-1.3-.7-2V30.7h2.9v8c0 1.1-.1 1.7 0 1.9.4 1.2 1.5.6 1.7-.1.1-.2 0-.8 0-1.9v-7.9h2.9v12.6zm4.4-1.2l-.2 1.1h-2.4v-17H24v4.9c2.7-2 4.6-.5 4.6 2v6.6c0 3.2-1.9 5.1-4.9 2.4zm6.6-3.2V35c0-1.2.1-2.1.4-2.8.2-.7.7-1.2 1.2-1.6 1.7-1.2 5.2-.8 5.6 1.5.1.8.1 2.1.1 3.4v1.8h-5v3c0 .6.5 1.1 1.2 1.1h.4c.6 0 1.2-.5 1.2-1.1v-1.5h2.2c1 5.7-7.3 6.6-7.3.1zM26.2 5.2v8.9c0 1.1 0 1.9.1 2.3 0 .4.1.7.3 1.1.1.3.4.6.7.8.3.2.7.3 1.2.3.4 0 .8-.1 1.1-.3.3-.2.6-.6.8-1l-.1 1.1h3.2V5.2H31v10.2c0 .6-.5 1-1.1 1-.6 0-1.1-.5-1.1-1V5.2h-2.6z"></path> <path d="M35.5 33.5c0-.7-.6-1.2-1.3-1.2H34c-.7 0-1.3.6-1.3 1.2V35h2.7l.1-1.5zm-10.3-1.7c-.7 0-1.3.4-1.3 1v7.4c0 .5.6 1 1.3 1 .7 0 1.3-.4 1.3-1v-7.4c-.1-.6-.6-1-1.3-1zm-8.3-15.7c.1.6.3 1.1.6 1.5.3.4.7.7 1.2.9.5.2 1.1.3 1.9.3.7 0 1.2-.1 1.7-.4.5-.2.9-.6 1.2-1.1.3-.5.5-1 .6-1.6.1-.6.2-1.5.2-2.7V9.7c0-1-.1-1.7-.2-2.2-.1-.5-.3-.9-.6-1.4-.3-.4-.7-.8-1.2-1.1-.5-.3-1.2-.4-1.9-.4-.9 0-1.6.2-2.2.6-.6.4-1 1-1.2 1.7-.2.7-.3 1.7-.3 3v3.5c.1 1.2.1 2.1.2 2.7zm2.4-7.9c0-.7.6-1.4 1.3-1.4.7 0 1.3.6 1.3 1.4v7c0 .7-.6 1.4-1.3 1.4-.7 0-1.3-.6-1.3-1.4v-7zm-8.9 10.6h3V8.7L17 0h-3.3l-1.9 6.4-2-6.4H6.6l3.8 8.7z"></path> </svg></a></div>
                        </div>
                    </div>
                </div>

                <div class="relative pb-3 mb-3">
                    <h4 class="mb-8 text-black font-semibold text-3xl">Hızlı Erişim</h4>
                    <ul>
                        <li class="block pt-1 pb-1"><a >Kataloglar</a></li>
                        <li class="block pt-1 pb-1 cursor-pointer"><a 
                                                                                            @click="router_push_to({
                                                                                                name:'kurumsal',
                                                                                                params: {
                                                                                                    page_link: 'cerez-politikasi'
                                                                                                }
                                                                                            })"
                                                                                            >Çerez Politikası</a>
                                                                                            
        
        </li>
                        <li class="block pt-1 pb-1 cursor-pointer"><a
                                                                                            @click="router_push_to({
                                                                                                name:'kurumsal',
                                                                                                params: {
                                                                                                    page_link: 'kvkk-aydinlatma-metni'
                                                                                                }
                                                                                            })"
                                                                                            >Kvkk Aydınlatma Metni</a></li>

                        <li class="block pt-1 pb-1 cursor-pointer"><a
                                                                                            @click="router_push_to({
                                                                                                name:'kurumsal',
                                                                                                params: {
                                                                                                    page_link: 'kisisel-verilerin-korunmasi-politikasi'
                                                                                                }
                                                                                            })"
                        
                        >Kişisel Verilerin Korunması Politikası</a></li>
                        <li class="block pt-1 pb-1 cursor-pointer"><a 

                                                                                            @click="router_push_to({
                                                                                                name:'kurumsal',
                                                                                                params: {
                                                                                                    page_link: 'iade-ve-teslimat'
                                                                                                }
                                                                                            })"
                        >İade ve Teslimat</a></li>
                        <li class="block pt-1 pb-1 cursor-pointer"><a 
                        
                                                                                            @click="router_push_to({
                                                                                                name:'kurumsal',
                                                                                                params: {
                                                                                                    page_link: 'mesafeli-satis-sozlesmesi'
                                                                                                }
                                                                                            })"
                        
                        >Mesafeli Satış Sözleşmesi</a></li>
                    </ul>
                </div>

                <div class="relative pb-3 mb-3">
                    <h4 class="mb-8 text-black font-semibold text-3xl">Finans</h4>
                    <ul>
                        <li class="block pt-1 pb-1"><a href="/payment">Yeni Ödeme Yap</a></li>
                        <li class="block pt-1 pb-1"><a href="#">Ödeme İşlem Hareketleri</a></li>
                        <li class="block pt-1 pb-1 cursor-pointer" @click="router_push_to({
                        name:'dash',
                        params: {
                                activetableroute: 'orders'
                            }
                        })">Cari Hesap Hareketleri</li>
                        <li class="block pt-1 pb-1"><a href="#">Kapatılmamış Faturalar</a></li>
                    </ul>
                </div>

                <div class="relative pb-3 mb-3">
                    <h4 class="mb-8 text-black font-semibold text-3xl">Hesabım</h4>
                    <ul>
                        <li class="block pt-1 pb-1  cursor-pointer" @click="router_push_to( {
                            name:'cart',
                            params: {
                                activetableroute : ''
                            }
                        })">Sepetim</li>
                        <li class="block pt-1 pb-1 cursor-pointer" @click="router_push_to({
                            name: 'dash',
                            params: {
                                activetableroute: 'favorites'
                            }
                        })">Favori Ürünlerim</li>
                        <li class="pt-1 pb-1 cursor-pointer hidden" @click="router_push_to({
                            name:'dash',
                            params: {
                                activetableroute: 'notifications'
                            }
                        })">Bildirimlerim</li>
                        <li class="block pt-1 pb-1 cursor-pointer" @click="router_push_to({
                            name: 'dash',
                            params: {
                                    activetableroute: 'orders'
                                },
                            }
                        )">Siparişlerim</li>
                        <li class="block pt-1 pb-1 cursor-pointer" @click="router_push_to({
                            name: 'dash',
                            params: {
                                    activetableroute: 'returns'
                                },
                            }
                        )">İadelerim</li>
                    </ul>
                </div>
            </div>

            <div class="w-full border-t border-solid border-gray-300">

                <div class="flex flex-col md:flex-row flex-nowrap grid-cols-2 w-full justify-between pt-6 pb-2 border-t border-solid border-white">

                    <div class="relative pb-6 mb-4">
                        <img src="/images/ortaksoft.png" title="OrtakSoft B2B Çözümleri" class="w-[130px] cursor-pointer" onclick="window.open('https://www.ortaksoft.com','_blank')">
                    </div>
                    <div class="pl-5 mt-2 mb-4">
                        <a href="https://www.ortaksoft.com" alt="OrtakSoft B2B Çözümleri" target="_blank">OrtakSoft B2B Çözümleri - © 2022 Tüm Hakları Saklıdır</a>
                    </div>
                    <div class="relative pl-5 mt-2 md:mt-0 pb-6 mb-4">
                        <p><span class="text-black">Ödeme Seçeneklerimiz :</span> <img src="/img/cc-logo.png" class="pr-16"> </p>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <!-- Back to top button -->
        <div 
        id="ScrollTopButton" 
        class="sticky w-full flex justify-end bottom-0 pb-5 pr-5 lg:pr-3 transition">
            <div class="w-24 h-24 u-center bg-gray-300 hover:bg-gray-400 mt-2.5 cursor-pointer transition-colors ease-out duration-200 rounded border-2 focus:outline-none focus:ring px-3 py-1.5 bg-alpha-60 hover:bg-alpha-80 text-white border-transparent" title="Yukarı Çık">
                <button @click="scrollToTop" class="" role="button" aria-label="Yukarı Kaydır">
                    <svg class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                    </svg>
                </button>
            </div>
        </div>
    
</footer>
</template>

<script>
import MegaLisV from '../mega_menu.vue'
import { mapActions, mapGetters } from 'vuex'// B 17.11.2022 guncel tags bilgileri aliniyor

export default {
    data () {
        return {
            bellow_y : false
            }
    },
    computed: {
        ...mapGetters({ // B 17.11.2022 guncel tags bilgileri aliniyor
            account_info : 'get_account',
            settings :     'get_settings'
        }),
        
        bellow_req()
        {
            //console.log(window.scrollY)
            return window.scrollY < 400
        }
    },
    components:{
        'menu_listv' : MegaLisV
    },
    mounted() {
        /*window.addEventListener("scroll", this.handleScroll);*/
    },
    beforeUnmount() {
        /*window.removeEventListener("scroll", this.handleScroll);*/
    },
    methods:
    {
        ...mapActions(['act_get_pages']),

        check_and_req_pages()
        {

            if( this.settings.pages == undefined || this.settings.pages == null || this.settings.pages.length == 1)
            {
                this.act_get_pages({})
            }    
        },

        router_push_to( locationNparams ){


            // B 17.11.2022 guncel tags bilgileri aliniyor
            if ((locationNparams.name == 'dash') || (locationNparams.name == 'cart' ) || (locationNparams.params.activetableroute == 'favorites')){
                this.get_account_info()
            }
            // E 17.11.2022 guncel tags bilgileri aliniyor

            // console.log(locationNparams)
            if( locationNparams.name == 'kurumsal') {
                this.check_and_req_pages();
            
            }
            this.$router.push({
                name:   locationNparams.name,
                params: locationNparams.params
            })

        },
        scrollToTop() {
                window.scrollTo({ top: 0, behavior: "smooth" });
        },

        check_hight() 
        {
            const const_h = 400
            window.onscroll = () => 
            {
                this.bellow_y =  window.scrollY < const_h
            }
        },

        async get_account_info(){

            if (this.account_info.lastlogindate != undefined){ // 10.03.2023 - Gunluk giris tarih kontrolu
              const controlToday    = new Date();
              const controlDate     = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
              const UserDate        = new Date(this.account_info.lastlogindate);
              const controlUserDate = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
              if (Number(controlDate) != Number(controlUserDate)){
                this.$router.push(
                    {name: 'login'}
                )
              } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }
              }
            } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor

                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }
            }





        },
    },
    mounted() {
        this.check_hight()
    },
    
}

// const actions = {
//         scrollToTop() {
//             window.scrollTo( { top: 0, behavior: "smooth" });
//         },
//         handleScroll() {
//             /*const scrollBtn = this.$refs.scrollTopButton;*/
//             const scrollBtn = document.getElementById('scrollTopButton');

//             if (window.scrollY > 500){
//                 scrollBtn.classList.remove("invisible");
//             } else {
//                 scrollBtn.classList.add("invisible");
//             }
//         },
// }
// window.addEventListener("scroll", actions.handleScroll);

</script>