<template>
  <nav class="navigation">
    <div class="ps-container">
      <div class="navigation__left">
        <div class="menu--product-categories">
          <div class="menu__toggle">
            <i class="icon-menu"></i><span> Shop by Department</span>
          </div>
          <div class="menu__content">
            <ul class="menu--dropdown">
              <li>
                <a href="#"><i class="icon-star"></i> Hot Promotions</a>
              </li>
              <li class="menu-item-has-children has-mega-menu">
                <a href="#"><i class="icon-laundry"></i> Consumer Electronic</a>
                <div class="mega-menu">
                  <div class="mega-menu__column">
                    <h4>Electronic<span class="sub-toggle"></span></h4>
                    <ul class="mega-menu__list">
                      <li><a href="#">Home Audio &amp; Theathers</a></li>
                      <li><a href="#">TV &amp; Videos</a></li>
                      <li><a href="#">Camera, Photos &amp; Videos</a></li>
                      <li><a href="#">Cellphones &amp; Accessories</a></li>
                      <li><a href="#">Headphones</a></li>
                      <li><a href="#">Videosgames</a></li>
                      <li><a href="#">Wireless Speakers</a></li>
                      <li><a href="#">Office Electronic</a></li>
                    </ul>
                  </div>
                  <div class="mega-menu__column">
                    <h4>
                      Accessories &amp; Parts<span class="sub-toggle"></span>
                    </h4>
                    <ul class="mega-menu__list">
                      <li><a href="#">Digital Cables</a></li>
                      <li><a href="#">Audio &amp; Video Cables</a></li>
                      <li><a href="#">Batteries</a></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <a href="#"
                  ><i class="icon-shirt"></i> Clothing &amp; Apparel</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="icon-lampshade"></i> Home, Garden &amp; Kitchen</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="icon-heart-pulse"></i> Health &amp; Beauty</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="icon-diamond2"></i> Yewelry &amp; Watches</a
                >
              </li>
              <li class="menu-item-has-children has-mega-menu">
                <a href="#"
                  ><i class="icon-desktop"></i> Computer &amp; Technology</a
                >
                <div class="mega-menu">
                  <div class="mega-menu__column">
                    <h4>
                      Computer &amp; Technologies<span
                        class="sub-toggle"
                      ></span>
                    </h4>
                    <ul class="mega-menu__list">
                      <li><a href="#">Computer &amp; Tablets</a></li>
                      <li><a href="#">Laptop</a></li>
                      <li><a href="#">Monitors</a></li>
                      <li><a href="#">Networking</a></li>
                      <li><a href="#">Drive &amp; Storages</a></li>
                      <li><a href="#">Computer Components</a></li>
                      <li><a href="#">Security &amp; Protection</a></li>
                      <li><a href="#">Gaming Laptop</a></li>
                      <li><a href="#">Accessories</a></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <a href="#"
                  ><i class="icon-baby-bottle"></i> Babies &amp; Moms</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="icon-baseball"></i> Sport &amp; Outdoor</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="icon-smartphone"></i> Phones &amp; Accessories</a
                >
              </li>
              <li>
                <a href="#"><i class="icon-book2"></i> Books &amp; Office</a>
              </li>
              <li>
                <a href="#"
                  ><i class="icon-car-siren"></i> Cars &amp; Motocycles</a
                >
              </li>
              <li>
                <a href="#"><i class="icon-wrench"></i> Home Improments</a>
              </li>
              <li>
                <a href="#"><i class="icon-tag"></i> Vouchers &amp; Services</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="navigation__right">
        <ul class="menu">

          <li class="menu-item-has-children">
            <a href="index.html">Home</a><span class="sub-toggle"></span>
            <ul class="sub-menu">
              <li><a href="index.html">Marketplace Full Width</a></li>
              <li><a href="homepage-2.html">Home Auto Parts</a></li>
              <li><a href="homepage-10.html">Home Technology</a></li>
              <li><a href="homepage-9.html">Home Organic</a></li>
              <li><a href="homepage-3.html">Home Marketplace V1</a></li>
              <li><a href="homepage-4.html">Home Marketplace V2</a></li>
              <li><a href="homepage-5.html">Home Marketplace V3</a></li>
              <li><a href="homepage-6.html">Home Marketplace V4</a></li>
              <li><a href="homepage-7.html">Home Electronic</a></li>
              <li><a href="homepage-8.html">Home Furniture</a></li>
              <li><a href="homepage-kids.html">Home Kids</a></li>
              <li>
                <a href="homepage-photo-and-video.html"
                  >Home photo and picture</a
                >
              </li>
              <li><a href="home-medical.html">Home Medical</a></li>
            </ul>
          </li>
          <li class="menu-item-has-children has-mega-menu">
            <a href="shop-default.html">Shop</a><span class="sub-toggle"></span>
            <div class="mega-menu">
              <div class="mega-menu__column">
                <h4>Catalog Pages<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="shop-default.html">Shop Default</a></li>
                  <li><a href="shop-default.html">Shop Fullwidth</a></li>
                  <li><a href="shop-categories.html">Shop Categories</a></li>
                  <li><a href="shop-sidebar.html">Shop Sidebar</a></li>
                  <li>
                    <a href="shop-sidebar-without-banner.html"
                      >Shop Without Banner</a
                    >
                  </li>
                  <li><a href="shop-carousel.html">Shop Carousel</a></li>
                </ul>
              </div>
              <div class="mega-menu__column">
                <h4>Product Layout<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="product-default.html">Default</a></li>
                  <li><a href="product-extend.html">Extended</a></li>
                  <li><a href="product-full-content.html">Full Content</a></li>
                  <li><a href="product-box.html">Boxed</a></li>
                  <li><a href="product-sidebar.html">Sidebar</a></li>
                  <li><a href="product-default.html">Fullwidth</a></li>
                </ul>
              </div>
              <div class="mega-menu__column">
                <h4>Product Types<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="product-default.html">Simple</a></li>
                  <li><a href="product-default.html">Color Swatches</a></li>
                  <li>
                    <a href="product-image-swatches.html">Images Swatches</a>
                  </li>
                  <li><a href="product-countdown.html">Countdown</a></li>
                  <li><a href="product-multi-vendor.html">Multi-Vendor</a></li>
                  <li><a href="product-instagram.html">Instagram</a></li>
                  <li><a href="product-affiliate.html">Affiliate</a></li>
                  <li><a href="product-on-sale.html">On sale</a></li>
                  <li><a href="product-video.html">Video Featured</a></li>
                  <li><a href="product-groupped.html">Grouped</a></li>
                  <li><a href="product-out-stock.html">Out Of Stock</a></li>
                </ul>
              </div>
              <div class="mega-menu__column">
                <h4>Woo Pages<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="shopping-cart.html">Shopping Cart</a></li>
                  <li><a href="checkout.html">Checkout</a></li>
                  <li><a href="whishlist.html">Whishlist</a></li>
                  <li><a href="compare.html">Compare</a></li>
                  <li><a href="order-tracking.html">Order Tracking</a></li>
                  <li><a href="my-account.html">My Account</a></li>
                  <li><a href="checkout-2.html">Checkout 2</a></li>
                  <li><a href="shipping.html">Shipping</a></li>
                  <li><a href="payment.html">Payment</a></li>
                  <li><a href="payment-success.html">Payment Success</a></li>
                </ul>
              </div>
            </div>
          </li>
          <li class="menu-item-has-children has-mega-menu">
            <a href="#">Pages</a><span class="sub-toggle"></span>
            <div class="mega-menu">
              <div class="mega-menu__column">
                <h4>Basic Page<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="about-us.html">About Us</a></li>
                  <li><a href="contact-us.html">Contact</a></li>
                  <li><a href="faqs.html">Faqs</a></li>
                  <li><a href="comming-soon.html">Comming Soon</a></li>
                  <li><a href="404-page.html">404 Page</a></li>
                </ul>
              </div>
              <div class="mega-menu__column">
                <h4>Vendor Pages<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="become-a-vendor.html">Become a Vendor</a></li>
                  <li><a href="vendor-store.html">Vendor Store</a></li>
                  <li>
                    <a href="vendor-dashboard-free.html"
                      >Vendor Dashboard Free</a
                    >
                  </li>
                  <li>
                    <a href="vendor-dashboard-pro.html">Vendor Dashboard Pro</a>
                  </li>
                  <li><a href="store-list.html">Store List</a></li>
                  <li><a href="store-list.html">Store List 2</a></li>
                  <li><a href="store-detail.html">Store Detail</a></li>
                </ul>
              </div>
              <div class="mega-menu__column">
                <h4>Account Pages<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="user-information.html">User Information</a></li>
                  <li><a href="addresses.html">Addresses</a></li>
                  <li><a href="invoices.html">Invoices</a></li>
                  <li><a href="invoice-detail.html">Invoice Detail</a></li>
                  <li><a href="notifications.html">Notifications</a></li>
                </ul>
              </div>
            </div>
          </li>



          
          <li class="menu-item-has-children has-mega-menu">
            <a href="#">Blogs</a><span class="sub-toggle"></span>
            <div class="mega-menu">
              <div class="mega-menu__column">
                <h4>Blog Layout<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="blog-grid.html">Grid</a></li>
                  <li><a href="blog-list.html">Listing</a></li>
                  <li><a href="blog-small-thumb.html">Small Thumb</a></li>
                  <li><a href="blog-left-sidebar.html">Left Sidebar</a></li>
                  <li><a href="blog-right-sidebar.html">Right Sidebar</a></li>
                </ul>
              </div>
              <div class="mega-menu__column">
                <h4>Single Blog<span class="sub-toggle"></span></h4>
                <ul class="mega-menu__list">
                  <li><a href="blog-detail.html">Single 1</a></li>
                  <li><a href="blog-detail-2.html">Single 2</a></li>
                  <li><a href="blog-detail-3.html">Single 3</a></li>
                  <li><a href="blog-detail-4.html">Single 4</a></li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
        <ul class="navigation__extra">
          <li><a href="#">Sell on Martfury</a></li>
          <li><a href="#">Tract your order</a></li>
          <li>
            <div class="ps-dropdown">
              <a href="#">US Dollar</a>
              <ul class="ps-dropdown-menu">
                <li><a href="#">Us Dollar</a></li>
                <li><a href="#">Euro</a></li>
              </ul>
            </div>
          </li>
          <li>
            <div class="ps-dropdown language">
              <a href="#"><img src="img/flag/en.png" alt="" />English</a>
              <ul class="ps-dropdown-menu">
                <li>
                  <a href="#"
                    ><img src="img/flag/germany.png" alt="" /> Germany</a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/flag/fr.png" alt="" /> France</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>