export default {
    methods: 
    {
      globalHelper: function () {
        alert("Hello world")
      },

      listbox_move(listID, direction) {

        var listbox = document.getElementById(listID);
        var selIndex = listbox.selectedIndex;

        if(-1 == selIndex) {
          //alert("Lütfen taşımak istediğiniz sütun başlığını seçiniz.");
          return;
        }

        var increment = -1;
        if(direction == 'up')
          increment = -1;
        else
          increment = 1;

        if((selIndex + increment) < 0 ||
          (selIndex + increment) > (listbox.options.length-1)) {
          return;
        }

        var selValue = listbox.options[selIndex].value;
        var selText = listbox.options[selIndex].text;
        listbox.options[selIndex].value = listbox.options[selIndex + increment].value
        listbox.options[selIndex].text = listbox.options[selIndex + increment].text

        listbox.options[selIndex + increment].value = selValue;
        listbox.options[selIndex + increment].text = selText;

        listbox.selectedIndex = selIndex + increment;
        
      },

      format_tl(numin,curr_code_in) {

        const curr_code = curr_code_in || " "
    
        const usd_format = Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 3,
                });
        const try_format = new Intl.NumberFormat("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                  minimumFractionDigits: 3,
                });
    
        const eur_format = Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 3,
                });
    
        const gbp_format =  Intl.NumberFormat('en-GB', {// BCP 47 language tag 
                style: 'currency', // we want a currency
                currency: 'GBP', // ISO 4217 currency code
                minimumFractionDigits: 3,
            });
    
        var retthis = try_format.format(numin)
        if(curr_code.includes("USD")) retthis = usd_format.format(numin);
        if(curr_code.includes("EUR")) retthis = eur_format.format(numin);
        if(curr_code.includes("GBP")) retthis = gbp_format.format(numin);
        return retthis
      },

      format_date_to_ms(date_value_in){

        var date_value = date_value_in.toString();
  
        if (date_value.indexOf(".") > 0){
  
          var parts = date_value.trim().split(' ');
          var date = parts[0].split('.');
          var time = (parts[1] ? parts[1] : '00:00:00').split(':');
  
          var d = new Date(date[2],String(date[1]).padStart(2,"0"),String(date[0]).padStart(2,"0"),String(time[0]).padStart(2,"0"),String(time[1]).padStart(2,"0"),String(time[2]).padStart(2,"0"));
  
          return Math.floor(d.getTime() / 1000)
  
        } else {
          return date_value
        }
      },

      format_date_local(date_value) {
        // console.log("format_Date is called");
        const date = new Date(date_value);
        const newdate = date.toLocaleDateString("tr-TR")
        return newdate
      },

      format_date_time_local(date_value) {
        // console.log("format_Date is called");
        const date = new Date(date_value);
        const newdate = date.toLocaleDateString("tr-TR") + " " + date.toLocaleTimeString("tr-TR", { hour12: false, hour: "2-digit", minute: "2-digit" })
        return newdate

        const dd = String(date.getDate()).padStart(2,"0");
        const mm = String(date.getMonth()+1).padStart(2,"0");
        const hr = String(date.getUTCHours()).padStart(2,"0");
        const mnt = String(date.getMinutes()).padStart(2,"0");
        const yyyy = date.getFullYear();
        const hour = date.getUTCHours();

        return `${dd}.${mm}.${yyyy}`
    },

      ToSeoUrl(url) {
        var find = ['Ç', 'Ş', 'Ğ', 'Ü', 'İ', 'Ö', 'ç', 'ş', 'ğ', 'ü', 'ö', 'ı', '+', '#'];
        var replace = ['c', 's', 'g', 'u', 'i', 'o', 'c', 's', 'g', 'u', 'o', 'i', '-', '-'];
        // url küçük harfe çevrildi         
        var encodedUrl = url.toString().toLowerCase(); 
        // url deki türkçe karakterler temizlendi
        encodedUrl = encodedUrl.split(/\ö/).join("o");
        encodedUrl = encodedUrl.split(/\ş/).join("s");
        encodedUrl = encodedUrl.split(/\ğ/).join("g");
        encodedUrl = encodedUrl.split(/\ı/).join("i");
        encodedUrl = encodedUrl.split(/\ü/).join("u");
        encodedUrl = encodedUrl.split(/\ç/).join("c");
        encodedUrl = encodedUrl.split(/\Ö/).join("O");
        encodedUrl = encodedUrl.split(/\Ş/).join("S");
        encodedUrl = encodedUrl.split(/\Ğ/).join("G");
        encodedUrl = encodedUrl.split(/\İ/).join("I");
        // & işareti -ve- yapıldı
        encodedUrl = encodedUrl.split(/\&+/).join("-ve-");
        // bilinmeyen karakterler kaldırıldı
        encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-"); 
        // çiftlemeler kaldırıldı
        encodedUrl = encodedUrl.split(/-+/).join("-");
        // baştaki ve sondaki karakterleri kırpıldı
        encodedUrl = encodedUrl.trim('-'); 
        return encodedUrl;
      },

      //24.09.2023 - havuz urunleri icin fiyat hesaplaniyor
      get_poolprice(vendorprices){

        var strPriceLCY         = 0
        var strnoCampaignPrice  = 0
        var strCampaignPrice    = 0
        var strStartingDate     = 0
        var strEndingDate       = 0
        var strToday            = Date.parse(new Date())

        for (const property in vendorprices) {
          for (const sub_property in vendorprices[property]){
            strPriceLCY     = vendorprices[property][sub_property]['Price'] * this.get_kurr(property)
            strStartingDate = vendorprices[property][sub_property]['StartingDate']
            strEndingDate   = vendorprices[property][sub_property]['EndingDate']

            strnoCampaignPrice = ((sub_property == "noCampaign") && ((strPriceLCY < strnoCampaignPrice) || (strnoCampaignPrice == 0)) && (((strEndingDate < 0) && (strToday >= strStartingDate)) || ((strEndingDate >= strToday) && (strToday >= strStartingDate)))) ? strPriceLCY : strnoCampaignPrice
            strCampaignPrice = ((sub_property == "Campaign") && ((strPriceLCY < strnoCampaignPrice) || (strCampaignPrice == 0)) && (((strEndingDate < 0) && (strToday >= strStartingDate)) || ((strEndingDate >= strToday) && (strToday >= strStartingDate)))) ? strPriceLCY : strCampaignPrice
            // console.log("Org. Fiyat:" + vendorprices[property][sub_property]['Price'] +" :: Döviz: " + property + " :: strnoCampaignPrice:" + strnoCampaignPrice +  " :: strCampaignPrice: " + strCampaignPrice + " :: strPriceLCY: " + strPriceLCY)
          }
        }
        return (strnoCampaignPrice > strCampaignPrice) ? strnoCampaignPrice : strCampaignPrice

      },

      get_kurr( curr_code_in ) {
        if (curr_code_in != undefined){
          if( curr_code_in.includes('EUR-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                  const curr_obj = this.exchange_rate.filter(
                      i=>{
                          return i.kurismi.includes('EURO')
                      }
                  ).pop()
  
                  return  parseFloat(curr_obj.forexsat)
              }
              if( curr_code_in.includes('USD-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                  const curr_obj = this.exchange_rate.filter(
                      i=>{
                          return i.kurismi.includes('US DOLLAR')
                      }
                  ).pop()
  
                  return  parseFloat(curr_obj.forexsat)
              }
              if( curr_code_in.includes('GBP-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                  const curr_obj = this.exchange_rate.filter(
                      i=>{
                          return i.kurismi.includes('POUND')
                      }
                  ).pop()
  
                  return parseFloat(curr_obj.forexsat)
              }
              // B 28.11.2022  -   MA-MS kur duzenlemesi yapildi
              if( curr_code_in.includes('EUR-MA')){
                  const curr_obj = this.exchange_rate.filter(
                      i=>{
                          return i.kurismi.includes('EURO')
                      }
                  ).pop()
  
                  return  parseFloat(curr_obj.forexal)
              }
              if( curr_code_in.includes('USD-MA')){
                  const curr_obj = this.exchange_rate.filter(
                      i=>{
                          return i.kurismi.includes('US DOLLAR')
                      }
                  ).pop()
  
                  return  parseFloat(curr_obj.forexal)
              }
              if( curr_code_in.includes('GBP-MA')){
                  const curr_obj = this.exchange_rate.filter(
                      i=>{
                          return i.kurismi.includes('POUND')
                      }
                  ).pop()
  
                  return parseFloat(curr_obj.forexal)
              }
              // E 28.11.2022  -   MA-MS kur duzenlemesi yapildi
        }
        return 1
      },
  

    },
  }